import { v4 as uuid } from "uuid";
import { useMemo } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "../../../../../components/TextField";
import Select from "../../../../../components/Select";

import styles from "./SeatOptionPricesForm.module.css";

import { SeatOptionUnitType } from "../../../../../types";
import { SeatOption, SeatOptionPriceJson } from "./SeatOptionsForm";
import SeatOptionAvailabilityForm from "./SeatOptionAvailabilityForm";

type SeatOptionPricesFormProps = {
  seatOption: SeatOption;
  seatOptionIdx: number;
  onChange: (fieldName: keyof SeatOption, index: number, value: any) => void;
};

export default function SeatOptionPricesForm({
  seatOption,
  seatOptionIdx,
  onChange,
}: SeatOptionPricesFormProps) {
  function setPriceField<T>(
    fieldName: keyof SeatOptionPriceJson,
    index: number,
    value: T
  ) {
    const prices = [...(seatOption.prices ?? [])];

    prices[index] = {
      ...prices[index],
      [fieldName]: value,
    };

    onChange("prices", seatOptionIdx, prices);
  }

  const addNewPrice = () => {
    const prices = [...(seatOption.prices ?? [])];
    prices.push({
      key: uuid(),
      price: 0,
      unitType: "ROOM",
      weekdays: [],
      holidays: [],
    });

    onChange("prices", seatOptionIdx, prices);
  };

  const removePrice = (priceIdx: number) => {
    const prices = [...(seatOption.prices ?? [])];

    onChange(
      "prices",
      seatOptionIdx,
      prices.filter((_, idx) => idx !== priceIdx)
    );
  };

  const selectedDays = useMemo(() => {
    return seatOption.prices?.reduce((accumulated, item) => {
      const result = [...accumulated, ...item.weekdays, ...item.holidays];

      return [...new Set(result)];
    }, [] as string[]);
  }, [seatOption]);

  return (
    <Card className={styles.Card}>
      <CardHeader
        title="Prices"
        action={
          <IconButton onClick={addNewPrice}>
            <AddIcon />
          </IconButton>
        }
        className={styles.CardHeader}
      />
      <CardContent>
        <Stack spacing={2}>
          {seatOption.prices?.map((item, idx) => (
            <Card variant="outlined" key={item.key}>
              <CardHeader
                className={styles.CardHeader}
                action={
                  idx !== 0 && (
                    <IconButton onClick={() => removePrice(idx)}>
                      <DeleteIcon />
                    </IconButton>
                  )
                }
              />
              <CardContent className={styles.CardContent}>
                <SeatOptionAvailabilityForm
                  price={item}
                  priceIdx={idx}
                  disabledDays={selectedDays}
                  onChange={setPriceField}
                />
                <TextField
                  id={`${seatOption.id}_price_${idx}`}
                  label="Price (optional additional fee)"
                  value={item.price || 0}
                  startAdornment={"¥"}
                  onChange={(e: any) => {
                    setPriceField("price", idx, Number(e.target.value ?? 0));
                  }}
                />
                <Select
                  style={{ marginTop: "10px" }}
                  label="Unit type"
                  value={item.unitType}
                  options={Object.entries(SeatOptionUnitType).map(
                    ([key, value]) => {
                      return {
                        id: key,
                        name: value,
                      };
                    }
                  )}
                  onChange={(option: string) => {
                    setPriceField("unitType", idx, option);
                  }}
                />
              </CardContent>
            </Card>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
