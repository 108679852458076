import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItemIcon,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextField from "../../../../components/TextField";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { useState, useCallback } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { cloneDeep } from "lodash";
import { useNotifications } from "../../../../components/Notification";
import { icons } from "../../icons";
import { v4 as uuidv4 } from "uuid";
import PlanImageDialog from "../../../../components/PlanImageDialog";
import { CloudinaryImage } from "../../../../components/Cloudinary";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";

export default function WhatsIncluded(props: WhatsIncludedProps) {
  return (
    <Card>
      <CardHeader title={"What's Included"} />
      <Divider />
      <CardContent>
        <VerticalDragList {...props} />
      </CardContent>
    </Card>
  );
}

interface WhatsIncludedProps {
  onChange: Function;
  planId?: string;
  inclusions: {
    category: string;
    items: ItemType[];
  }[];
}
interface ItemType {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  iconName: string;
}

function VerticalDragList(props: WhatsIncludedProps) {
  const { showNotification } = useNotifications();
  const items = props.inclusions;
  const { onChange, planId } = props;
  const grid = 10;

  const [categoryAssign, setCategoryAssign] = useState("");
  const [itemTitle, setItemTitle] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemImageUrl, setItemImageUrl] = useState<string | null>(null);
  const [selectedIcon, setIcon] = useState("GoPrimitiveDot");
  const [openModalImage, setOpenModalImage] = useState(false);

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = [...list.items];

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return {
      category: list.category,
      items: result,
    };
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#77e2e0" : "#fff",
    border: "1px solid #ccc",
    borderRadius: 5,
    ...draggableStyle,
  });

  const getListStyle = () => ({
    padding: grid,
    flexDirection: "column",
  });

  function setInclusionItem(index: number, itemIndex: number, item: ItemType) {
    // deep clone required to modify nested properties
    const copy = cloneDeep(items);
    copy[itemIndex].items[index] = item;

    try {
      onChange(copy);
      showNotification({
        message:
          "Changes have been applied locally. Please don't forget to save.",
        severity: "success",
      });
    } catch (error: any) {
      showNotification({
        message: `Something went wrong. ${error.message}`,
        severity: "success",
      });
    }
  }

  const onDragEnd = useCallback(
    (result: any, category: string, onChange: Function) => {
      if (!result.destination) {
        return;
      }
      const reorderItems = reorder(
        items.find((f) => f.category === category),
        result.source.index,
        result.destination.index
      );
      const newList = items.map((item) => {
        if (item.category === category) {
          return reorderItems;
        }
        return item;
      });
      onChange(newList as any);
    },
    [items]
  );

  const removeItem = (i: any) => {
    const updatedList = items
      .map((item) => {
        let items = item.items.filter((l) => l.title !== i.title);
        return {
          ...item,
          items,
        };
      })
      .filter((u) => u.items.length > 0);
    onChange(updatedList as any);
  };

  return (
    <>
      <PlanImageDialog
        planId={planId!}
        imageUrl={itemImageUrl}
        open={openModalImage}
        onConfirm={(imageUrl: string | null) => {
          setItemImageUrl(imageUrl);
        }}
        handleClose={() => setOpenModalImage(false)}
      />
      <section
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {items.map((item, itemIndex) => {
          return (
            <DragDropContext
              key={itemIndex}
              onDragEnd={(result: any) =>
                onDragEnd(result, item.category, onChange)
              }
            >
              <Droppable droppableId={`droppable-${itemIndex}`}>
                {(provided: any) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle()}
                  >
                    <h3>
                      {itemIndex + 1}: {item.category}
                    </h3>
                    {item.items.map((i, index) => {
                      return (
                        <Draggable
                          key={i.title}
                          draggableId={i.title}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <IncludedCard
                                i={i}
                                index={index}
                                itemIndex={itemIndex}
                                removeItem={removeItem}
                                updateInclusionList={setInclusionItem}
                                planId={planId}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          );
        })}
      </section>
      <section
        style={{
          marginTop: 20,
        }}
      >
        {itemImageUrl ? (
          <FormControl
            variant="outlined"
            style={{ width: "max-content", marginRight: 10 }}
          >
            <ClearRoundedIcon
              onClick={() => {
                setItemImageUrl(null);
              }}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: -22,
                right: -5,
              }}
            />
            <CloudinaryImage
              style={{ width: 70, height: 56 }}
              src={itemImageUrl}
              transformations={["w_400"]}
            />
          </FormControl>
        ) : (
          <FormControl
            variant="outlined"
            style={{
              width: 70,
              height: 55,
              marginRight: 10,
            }}
          >
            <InputLabel htmlFor="outlined-category-native-simple">
              Icon
            </InputLabel>
            <IconSelector selectedIcon={selectedIcon} setIcon={setIcon} />
          </FormControl>
        )}
        <FormControl>
          <MediaUploader
            allowedTypes={[MediaType.Image]}
            media={null}
            uploadPath={`plans/${planId}/faqs`}
            onChangeFunction={(e) => {
              setItemImageUrl(e);
            }}
            styles={{
              marginRight: 10,
              backgroundColor: "#c8a063",
              height: "56px",
              color: "white",
            }}
            buttonText="UPLOAD IMAGE"
            isButtonVisible
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          style={{
            marginRight: 10,
            backgroundColor: "#c8a063",
            height: "56px",
          }}
          onClick={() => setOpenModalImage(true)}
        >
          SELECT IMAGE
        </Button>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-category-native-simple">
            Category
          </InputLabel>

          <Select
            native
            value={categoryAssign}
            onChange={(e: any) => setCategoryAssign(e.target.value)}
            label="Category"
            style={{
              height: 56,
            }}
            inputProps={{
              name: "category",
              id: "outlined-category-native-simple",
            }}
          >
            <option aria-label="None" value="" />
            <option value={"お食事"}>お食事</option>
            <option value={"特典ギフト"}>特典ギフト</option>
            <option value={"体験・移動"}>体験・移動</option>
            <option value={"含まれないもの"}>含まれないもの</option>
            <option value={"お席"}>お席</option>
            <option value={"選べるもの"}>選べるもの</option>
          </Select>
        </FormControl>
        <TextField
          label="title"
          value={itemTitle}
          onChange={(e: any) => setItemTitle(e.target.value)}
        />
        <TextField
          label="description"
          value={itemDescription}
          onChange={(e: any) => setItemDescription(e.target.value)}
        />
        <Button
          style={{
            marginTop: 20,
          }}
          disabled={!itemTitle || !selectedIcon || !categoryAssign}
          variant="contained"
          color="primary"
          onClick={() => {
            if (items.find((item) => item.category === categoryAssign)) {
              let obj = {
                title: itemTitle,
                description: itemDescription,
                iconName: selectedIcon,
                imageUrl: itemImageUrl,
                id: uuidv4(),
              };
              let updatedList = items.map((item) => {
                if (item.category === categoryAssign) {
                  return {
                    ...item,
                    items: item.items.concat(obj as any),
                  };
                }
                return item;
              });
              onChange(updatedList);
              setItemTitle("");
              setItemDescription("");
              setItemImageUrl(null);
            } else {
              let obj = {
                category: categoryAssign,
                items: [
                  {
                    title: itemTitle,
                    description: itemDescription,
                    iconName: selectedIcon,
                    imageUrl: itemImageUrl,
                    id: uuidv4(),
                  },
                ],
              };
              let newList = items.concat(obj as any);
              onChange(newList);
              setItemTitle("");
              setItemDescription("");
              setItemImageUrl(null);
            }
          }}
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </section>
    </>
  );
}

function IncludedCard({
  i,
  index,
  itemIndex,
  removeItem,
  updateInclusionList,
  planId,
}: any) {
  const { showNotification } = useNotifications();
  const [isUnchanged, setIsUnchanged] = useState(true);
  const [itemTitle, setItemTitle] = useState(i.title);
  const [itemDescription, setItemDescription] = useState(i.description);
  const [itemImageUrl, setItemImageUrl] = useState(i.imageUrl);
  const [selectedIcon, setIcon] = useState(i.iconName);
  const [openModalImage, setOpenModalImage] = useState(false);

  return (
    <>
      <PlanImageDialog
        planId={planId}
        imageUrl={itemImageUrl}
        open={openModalImage}
        onConfirm={(imageUrl: string | null) => {
          setIsUnchanged(false);
          setItemImageUrl(imageUrl);
        }}
        handleClose={() => setOpenModalImage(false)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: i.imageUrl || itemImageUrl ? "center" : "unset",
        }}
      >
        {itemImageUrl ? (
          <div>
            <ClearRoundedIcon
              onClick={() => {
                setIsUnchanged(false);
                setItemImageUrl(null);
              }}
              sx={{ float: "right", cursor: "pointer" }}
            />
            <CloudinaryImage
              style={{ width: "100px", height: "100px" }}
              src={itemImageUrl}
              transformations={["w_400"]}
            />
          </div>
        ) : (
          <IconSelector
            key={`selectedIcon-${itemIndex}-${index}`}
            identifier={`selectedIcon-${itemIndex}-${index}`}
            selectedIcon={selectedIcon}
            setIcon={setIcon}
            setChangeStatus={setIsUnchanged}
          />
        )}
        <div
          style={{
            flexDirection: "column",
            marginLeft: 10,
          }}
        >
          <TextField
            size="small"
            label={"Title"}
            value={itemTitle}
            onChange={(e: any) => {
              setItemTitle(e.target.value);
              setIsUnchanged(false);
            }}
          ></TextField>
          <TextField
            label={"Description"}
            multiline
            value={itemDescription}
            onChange={(e: any) => {
              setItemDescription(e.target.value);
              setIsUnchanged(false);
            }}
          ></TextField>

          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "5px", fontSize: "12px" }}
            onClick={() => {
              removeItem(i);
              showNotification({
                message:
                  "Item was removed locally. Please don't forget to save.",
                severity: "success",
              });
              setIsUnchanged(true);
            }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            disabled={isUnchanged}
            style={{ marginTop: "5px", marginLeft: "5px", fontSize: "12px" }}
            onClick={() =>
              updateInclusionList(index, itemIndex, {
                id: i.id,
                title: itemTitle,
                description: itemDescription,
                iconName: selectedIcon,
                imageUrl: itemImageUrl,
              })
            }
          >
            Apply Change
          </Button>
          <Button>
            <MediaUploader
              allowedTypes={[MediaType.Image]}
              media={null}
              uploadPath={`plans/${planId}/faqs/${i.id}`}
              onChangeFunction={(e) => {
                setIsUnchanged(false);
                setItemImageUrl(e);
              }}
              styles={{
                backgroundColor: "#c8a063",
                fontSize: "12px",
                color: "white",
                marginTop: 5,
              }}
              buttonText="UPLOAD IMAGE"
              isButtonVisible
            />
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#c8a063",
              fontSize: "12px",
              marginTop: 5,
            }}
            onClick={() => setOpenModalImage(true)}
          >
            SELECT IMAGE
          </Button>
        </div>
      </div>
    </>
  );
}

function IconSelector(props: IconSelectorProps) {
  return (
    <Select
      key={props.identifier || props.selectedIcon}
      value={props.selectedIcon}
      onChange={(e: any) => {
        props.setIcon(e.target.value);
        if (props.setChangeStatus) props.setChangeStatus(false);
      }}
      label="Icon"
    >
      {Array.from(icons).map(([value, icon]) => (
        <MenuItem value={value} key={value}>
          <ListItemIcon>{icon}</ListItemIcon>
        </MenuItem>
      ))}
    </Select>
  );
}

interface IconSelectorProps {
  selectedIcon: string;
  setIcon: React.Dispatch<React.SetStateAction<string>>;
  setChangeStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  identifier?: string;
}
