import { gql, useQuery } from "@apollo/client";
import { Tooltip } from "@mui/material";
import { format } from "date-fns-tz";
import { DownloadPdf } from "./DownloadPDF";

export function DownloadInvoiceOrItemSet({
  venue,
  date,
  isItemSet = false,
  disabled,
}: DownloadInvoiceOrItemSetProps) {
  const noCompany = venue.company === null;
  const noBankAccount = venue.company?.bankAccount === undefined;

  const variables: GetInvoiceInput = {
    month: format(date!, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", {
      timeZone: "GMT",
    }),
    venueId: venue.id,
  };

  const { data: itemSetDataCheck } = useQuery(CHECK_ITEMSET_DATA, {
    variables: {
      month: format(date!, "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx", {
        timeZone: "GMT",
      }),
      venueId: venue.id,
    },
    skip: !isItemSet,
  });

  const noItemSetData =
    isItemSet && !itemSetDataCheck?.checkItemSetData?.hasData;

  return (
    <Tooltip
      title={
        noCompany
          ? "No company is assigned to this venue"
          : noBankAccount
          ? "No bankaccount is assigned to this company"
          : ""
      }
    >
      <span>
        {isItemSet ? (
          <DownloadPdf
            venue={venue}
            date={date}
            pdfType="アイテムセットお支払通知書"
            query={GET_ITEMSET_INVOICE}
            variables={variables}
            buttonProps={{
              disabled: noCompany || noBankAccount || noItemSetData || disabled,
            }}
          >
            Download ItemSet PDF
          </DownloadPdf>
        ) : (
          <DownloadPdf
            venue={venue}
            date={date}
            pdfType="ご請求書"
            query={GET_INVOICE}
            variables={variables}
            buttonProps={{
              disabled: noCompany || noBankAccount || disabled,
            }}
          >
            Download Invoice PDF
          </DownloadPdf>
        )}
      </span>
    </Tooltip>
  );
}

type DownloadInvoiceOrItemSetProps = {
  venue: {
    id: string;
    name: string;
    company?: {
      id: string;
      bankAccount?: {
        id: string;
      };
    };
  };
  date: Date;
  isItemSet?: boolean;
  disabled?: boolean;
};

const GET_INVOICE = gql`
  query GetPaymentNotice($venueId: String!, $month: DateTime!) {
    fetchOrGenerateInvoicePdf(
      input: { venueId: $venueId, month: $month, forceOverwrite: true }
    ) {
      pdfData
      error
    }
  }
`;

const GET_ITEMSET_INVOICE = gql`
  query GetItemSetInvoice($venueId: String!, $month: DateTime!) {
    fetchOrGenerateItemSetPdf(
      input: { venueId: $venueId, month: $month, forceOverwrite: true }
    ) {
      pdfData
      error
    }
  }
`;

const CHECK_ITEMSET_DATA = gql`
  query CheckItemSetData($venueId: String!, $month: DateTime!) {
    checkItemSetData(input: { venueId: $venueId, month: $month }) {
      hasData
    }
  }
`;

type GetInvoiceInput = {
  venueId: string;
  month: string;
  forceOverwrite?: Boolean;
};
