import { useMemo, useState } from "react";
import DataTable from "../../../components/DataTable";
import { Tag } from "../../../types";
import { Card, CardContent, IconButton, Modal, Stack } from "@mui/material";
import Button from "../../../components/Button";
import AddIcon from "@mui/icons-material/Add";
import EditTag from "../Edit";
import EditIcon from "@mui/icons-material/Edit";
import useTags from "../../../hooks/useTags";
import DeleteTagButton from "./DeleteTagButton";
import Switch from "@mui/material/Switch";

export default function TagsList() {
  const { getFilteredTags, tagCategories, tagNames } = useTags();
  const [filter, setFilter] = useState("");
  const [selectedTag, setSelectedTag] = useState<Tag | undefined | null>(null);

  const rows = useMemo(() => getFilteredTags(filter), [
    filter,
    getFilteredTags,
  ]);
  const openModal = useMemo(() => selectedTag !== null, [selectedTag]);

  const columns = [
    { name: "id", field: "id" },
    { name: "name", field: "name" },
    { name: "category", render: (tag: Tag) => <p>{tag.tagCategory.name}</p> },
    { name: "slug", field: "slug" },
    { name: "group", field: "groupName" },
    { name: "display", render: (tag: Tag) => <DisplayTag tag={tag} /> },
    {
      name: "",
      render: (tag: Tag) => (
        <Stack direction="row">
          <IconButton onClick={() => setSelectedTag(tag)}>
            <EditIcon />
          </IconButton>
          <DeleteTagButton id={tag.id} />
        </Stack>
      ),
    },
  ];

  const TopBar = (
    <Button
      style={{ backgroundColor: "white", color: "#c8a063" }}
      variant="contained"
      startIcon={<AddIcon />}
      label={"Add Tag"}
      onClick={() => setSelectedTag(undefined)}
    >
      New
    </Button>
  );

  return (
    <>
      <DataTable
        rows={rows}
        columns={columns}
        topBarExtras={TopBar}
        onSearch={(event) => {
          setFilter(event.target.value);
        }}
      />
      <Modal open={openModal} onClose={() => setSelectedTag(null)}>
        <Card
          sx={{
            maxWidth: "480px",
            position: "relative",

            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CardContent>
            {selectedTag !== null && (
              <EditTag
                selectedTag={selectedTag}
                tagNames={tagNames}
                tagCategories={tagCategories}
                onSaveCallback={() => setSelectedTag(null)}
              />
            )}
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}

function DisplayTag({ tag }: { tag: Tag }) {
  const {
    editTag,
    editTagResponse: { loading },
  } = useTags();
  return (
    <Switch
      checked={tag.display}
      onChange={async (e) => {
        await editTag({
          variables: {
            id: tag?.id,
            name: tag.name,
            slug: tag.slug,
            groupName: tag.groupName,
            display: e.target.checked ?? false,
            tagCategoryId: tag.tagCategory.id,
          },
        });
      }}
    />
  );
}
