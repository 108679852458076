import React, { useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import {
  useQuery,
  gql,
  useLazyQuery,
  ApolloError,
  useMutation,
} from "@apollo/client";
import {
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Typography,
  Tooltip,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Overview from "./Overview";
import { startOfMonth, addMonths } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "../../../components/TextField";
import { format } from "date-fns-tz";
import { useNotifications } from "../../../components/Notification";
import Money from "./Money";
import EmailPreviewModal from "./EmailPreviewModal";

const lastMonth = startOfMonth(addMonths(new Date(), -1));

const DOWNLOAD_TYPE = "download";
const SENT_MAIL_STATUS = {
  FAIL: 3,
  SUCCESS: 2,
};

export default function VenueCosts() {
  const navigate = useNavigate();
  const location = useLocation();
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState<boolean | null>(null);
  const [listCompanyIds, setListCompanyIds] = useState<string[] | null>(null);
  const [listVenueIds, setListVenueIds] = useState<string[] | null>(null);
  const [yearMonth, setYearMonth] = useState<Date | null>(lastMonth);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [reportVenues, setReportVenues] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [modalTableList, setModalTableList] = useState([]);
  const rowsPerPage = 10;
  const parsedQueryString = queryString.parse(location.search);
  const { showNotification } = useNotifications();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [currentEmailIndex, setCurrentEmailIndex] = useState(0);
  const [page, setPage] = React.useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = React.useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );
  const [fetchDataModalLoading, setFetchDataModalLoading] = useState(false);

  const [
    fetchFileZipPdfExport,
    { loading: downloadLoading, data: downloadData, error: downloadError },
  ] = useLazyQuery(FETCH_FILE_ZIP_PDF_EXPORT, { fetchPolicy: "network-only" });

  const [
    fetchAllFileZipPdfExport,
    {
      loading: downloadAllLoading,
      data: downloadAllData,
      error: downloadAllError,
    },
  ] = useLazyQuery(FETCH_ALL_FILE_ZIP_PDF_EXPORT, {
    fetchPolicy: "network-only",
  });

  const [saveCompanyMutation] = useMutation(SAVE_COMPANY_MUTATION);
  const [
    sendEmail,
    { data: dataSendMail, loading: loadingSendMail, error: errorSendMail },
  ] = useLazyQuery(SEND_EMAIL, {
    fetchPolicy: "network-only",
    onError: (err: ApolloError) => {
      showNotification({
        message: "Send emails error",
        severity: "error",
      });
      console.error("GraphQL error:", err.message);
    },
    onCompleted: (data: any) => {
      if (data.sendVenueNoticeEmails.success) {
        showNotification({
          message: "Send emails Success",
          severity: "success",
        });
      } else {
        showNotification({
          message: "Send emails error",
          severity: "error",
        });
      }
    },
  });

  useEffect(() => {
    const urlParams = {
      page: page + 1,
      search: search,
    };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }, [page, search, navigate]);

  const resetPagination = () => {
    setPage(0);
    setTotalRows(0);
  };

  const from = yearMonth ? format(yearMonth, "yyyy-MM-dd") : null;
  const until = yearMonth
    ? format(startOfMonth(addMonths(yearMonth, 1)), "yyyy-MM-dd")
    : null;

  const { loading, error, data } = useQuery(GET_VENUE_RESERVATIONS, {
    variables: {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search,
      from,
      until,
      hasPaidReservations: {
        from,
        until,
      },
    },
  });

  const { refetch: refetchReport } = useQuery(REPORT_VENUE_QUERY, {
    variables: {
      from: yearMonth ? format(yearMonth, "yyyy-MM-dd'T'00:00:00'Z'") : null,
      search,
    },
    skip: true,
  });

  const { refetch: refetchCompanyVenue } = useQuery(FETCH_COMPANY_VENUE, {
    variables: {
      from: yearMonth ? format(yearMonth, "yyyy-MM-dd'T'00:00:00'Z'") : null,
      search,
    },
    skip: true,
  });

  const rows = useMemo(
    () =>
      [...(data?.venues.records ?? [])].map((venue) => ({
        ...venue,
        reservations: venue.activities.flatMap((activity: any) =>
          activity.reservations.map((reservation: any) => ({
            ...reservation,
            status:
              reservation.booking.status === "CANCELLED"
                ? "CANCELLED"
                : reservation.status,
            activityName: activity.name,
            planName: activity.plan.name,
            priceTypes: activity.priceTypes,
          }))
        ),
      })),
    [data]
  );

  const [totalRows, setTotalRows] = useState(0);
  const [checked, setChecked] = useState<boolean[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [zipNameFile, setZipNameFile] = useState<string>("");
  const checkedEmails = useMemo(() => {
    return modalTableList.filter((_, index: number) => checked[index]);
  }, [checked]);

  useEffect(() => {
    setChecked(companyList.map(() => false));
  }, [setCompanyList]);

  useEffect(() => {
    if (modalType === DOWNLOAD_TYPE) {
      setChecked(reportVenues.map(() => false));
    } else {
      setChecked(companyList.map(() => false));
    }
  }, [reportVenues, companyList]);

  useEffect(() => {
    if (data === undefined) return;
    setTotalRows(data.venues.total);
  }, [data]);

  useEffect(() => {
    if (downloadData) {
      if (downloadData.fetchFileZipPdfExport.filezip) {
        const zipData = downloadData.fetchFileZipPdfExport.filezip;
        if (!zipData) return;

        const data = Uint8Array.from(atob(zipData), (c) => c.charCodeAt(0));
        const blob = new Blob([data], {
          type: "application/zip",
        });

        exportBlob(blob, `${zipNameFile}.zip`);
      } else if (downloadData.fetchFileZipPdfExport.error) {
        console.error(
          "Error fetching report: ",
          downloadData.fetchFileZipPdfExport.error
        );
        showNotification({
          message: downloadData.fetchFileZipPdfExport.error,
          severity: "error",
        });
      }
    }
  }, [downloadData]);

  useEffect(() => {
    if (downloadAllData) {
      if (downloadAllData.fetchAllFileZipPdfExport.filezip) {
        const zipData = downloadAllData.fetchAllFileZipPdfExport.filezip;
        if (!zipData) return;

        const data = Uint8Array.from(atob(zipData), (c) => c.charCodeAt(0));
        const blob = new Blob([data], {
          type: "application/zip",
        });

        exportBlob(blob, `${zipNameFile}.zip`);
      } else if (downloadAllData.fetchAllFileZipPdfExport.error) {
        console.error(
          "Error fetching report: ",
          downloadAllData.fetchAllFileZipPdfExport.error
        );
        showNotification({
          message: downloadAllData.fetchAllFileZipPdfExport.error,
          severity: "error",
        });
      }
    }
  }, [downloadAllData]);

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  if (error) return <>Error! {error.message}</>;

  const handleModalOpen = async (type: string) => {
    setModalType(type);
    setOpenModal(true);
    setFetchDataModalLoading(true);
    if (type === DOWNLOAD_TYPE) {
      const { data: fetchedReportData } = await refetchReport();
      const fetchedReportVenues = fetchedReportData.getReportVenues.records.map(
        (record: any) => ({
          id: record.venue.id,
          venueName: record.venue.name,
          totalAmount: record.totalAmount,
          exportedAt: record.exportTime,
          url: record.url,
        })
      );
      setReportVenues(fetchedReportVenues);
      setModalTableList(fetchedReportVenues);
      setFetchDataModalLoading(false);
    } else {
      const { data: fetchedReportData } = await refetchCompanyVenue();
      const fetchedReportVenues = fetchedReportData.companiesToSendMail.records.map(
        (record: any) => {
          return {
            id: record.company_id,
            venueName: record.company_name,
            venue: record.venue_name,
            venueId: record.venue_id,
            venueInvoiceEmails: record.venue_invoice_emails,
            totalAmount: record.total_amount,
            manualResponse: record.manual_response,
            item_set: record.item_set,
            invoice: record.invoice,
            payment_notice: record.payment_notice,
            exportedAt: record.send_time,
            mailStatus: record.mail_status,
          };
        }
      );
      setCompanyList(fetchedReportVenues);
      setModalTableList(fetchedReportVenues);
      setFetchDataModalLoading(false);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setAllChecked(false);
    setChecked(reportVenues.map(() => false));
    setReportVenues([]);
    setCompanyList([]);
    setModalTableList([]);
  };

  const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setAllChecked(isChecked);
    const updatedChecked = modalTableList.map((item: any) => {
      if (modalType !== DOWNLOAD_TYPE && item.manualResponse === 1) {
        return false; // Không select dòng đã check 手動対応 hoặc đã gửi thành công
      }
      return isChecked;
    });

    setChecked(updatedChecked);
  };

  const handleToggleCheckbox = (index: number) => {
    const updatedChecked = [...checked];
    updatedChecked[index] = !updatedChecked[index];
    setChecked(updatedChecked);

    if (updatedChecked.every((isChecked) => isChecked)) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  const handleDownload = (type: number) => {
    const selectedVenues = modalTableList.filter(
      (_, index: number) => checked[index]
    );
    const selectedVenueIds = selectedVenues.map((venue: any) => venue.id);
    if (selectedVenueIds.length === 0) {
      showNotification({ message: "No record checked", severity: "error" });
      return;
    }
    const month = yearMonth
      ? format(yearMonth, "yyyy-MM-dd'T'00:00:00'Z'")
      : null;

    let yymm = "";
    if (yearMonth) {
      const year = yearMonth.getFullYear().toString().slice(-2);
      const month = (yearMonth.getMonth() + 1).toString().padStart(2, "0");
      yymm = `_${year}${month}`;
    }
    if (type === 2) {
      setZipNameFile(`【Annyお祝い体験】ご請求書${yymm}`);
    } else if (type === 1) {
      setZipNameFile(`【Annyお祝い体験】お支払通知書${yymm}`);
    } else {
      setZipNameFile(`【Annyお祝い体験】アイテムセット${yymm}`);
    }

    try {
      if (allChecked && !search) {
        fetchAllFileZipPdfExport({
          variables: {
            month,
            type,
          },
        });
      } else {
        // Use fetchFileZipPdfExport for selected venues
        fetchFileZipPdfExport({
          variables: {
            venueIds: selectedVenueIds,
            month,
            type,
          },
        });
      }
      handleModalClose();
    } catch (error) {
      console.error("Error during invoice download: ", error);
      showNotification({
        message: "Error during invoice download",
        severity: "error",
      });
    }
  };

  const handleClosePreviewModal = () => {
    setCurrentEmailIndex(0);
    setOpenPreviewModal(false);
    setListCompanyIds(null);
    setListVenueIds(null);
  };

  const handleSendEmail = () => {
    if (checkedEmails.length === 0) {
      showNotification({
        message: "No company selected.",
        severity: "error",
      });
      return;
    }
    const companyIds = checkedEmails.map((company: any) => company.id);
    const venueIds = checkedEmails
      .map((company: any) => company.venueId.split(","))
      .flat(Infinity);
    console.log(companyIds);
    console.log(venueIds);

    sendEmail({
      variables: {
        companyIds,
        venueIds,
        month: yearMonth ? format(yearMonth, "yyyy-MM-dd") : null,
      },
    });
    handleModalClose();
  };

  const handleCheckboxChange = async () => {
    const input = {
      id: companyId,
      manualResponse: isChecked ? 1 : 0,
      from: from,
    };

    try {
      const { data } = await saveCompanyMutation({
        variables: { input },
      });
      if (data.updateManualResponseStatus.success) {
        console.log("Manual response updated successfully");

        setModalTableList((prevList: any) =>
          prevList.map((company: any) =>
            company.id === companyId
              ? { ...company, manualResponse: isChecked ? 1 : 0 }
              : company
          )
        );
      } else {
        console.error("Error:", data.updateManualResponseStatus.error);
      }
      setCompanyId(null);
      setIsChecked(null);
    } catch (error) {
      setCompanyId(null);
      setIsChecked(null);
      console.error("Mutation failed:", error);
    }
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <div style={{ flexShrink: 0 }}>
              <DatePicker
                label="Month and Year"
                value={yearMonth}
                onChange={(event) => {
                  setYearMonth(event);
                  resetPagination();
                }}
                views={["year", "month"]}
                openTo="month"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {!!totalRows && (
                <Typography style={{ whiteSpace: "nowrap" }}>
                  {page * rowsPerPage + 1} -{" "}
                  {Math.min(totalRows, (page + 1) * rowsPerPage + 1)} of{" "}
                  {totalRows}
                </Typography>
              )}
              <div style={{ display: "flex" }}>
                <IconButton
                  disabled={page === 0 || totalRows === 0}
                  onClick={() => setPage((page) => Math.max(0, page - 1))}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton
                  disabled={page === totalPages - 1 || totalRows === 0}
                  onClick={() =>
                    setPage((page) => Math.min(totalPages - 1, page + 1))
                  }
                >
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </div>
            <TextField
              label="Search"
              style={{ flexShrink: 1 }}
              value={search ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
                resetPagination();
              }}
            />
          </div>
        </CardContent>
        <Box
          display="flex"
          sx={{
            gap: "10px",
            justifyContent: "end",
            marginRight: "20px",
            marginBottom: "15px",
          }}
        >
          <Tooltip title={"Download PDF"}>
            <span>
              {downloadLoading || downloadAllLoading ? (
                <Button
                  sx={{
                    backgroundColor: "#2196F3",
                    color: "#FFFFFF",
                    pointerEvents: "none",
                  }}
                  variant="outlined"
                  onClick={() => {}}
                >
                  EXPORT IN PROGRESS
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => handleModalOpen(DOWNLOAD_TYPE)}
                >
                  Download PDF
                </Button>
              )}
            </span>
          </Tooltip>
          <Tooltip title={"SEND EMAIL"}>
            <span>
              {loadingSendMail ? (
                <Button
                  sx={{
                    backgroundColor: "#2196F3",
                    color: "#FFFFFF",
                    pointerEvents: "none",
                  }}
                  variant="outlined"
                  onClick={() => {}}
                >
                  SEND EMAIL IN PROGRESS
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => handleModalOpen("sendEmail")}
                >
                  SEND EMAIL
                </Button>
              )}
            </span>
          </Tooltip>
        </Box>
      </Card>

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: modalType === DOWNLOAD_TYPE ? "800px" : "1400px", // Set your width here
            },
          },
        }}
        open={openModal}
        onClose={handleModalClose}
      >
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle sx={{ fontWeight: "bold" }}>{`${
              modalType === DOWNLOAD_TYPE ? "Venue List" : "Company List"
            }`}</DialogTitle>
            <DialogActions>
              {modalType === DOWNLOAD_TYPE ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleDownload(3);
                    }}
                  >
                    DOWNLOAD ITEM SET
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleDownload(1);
                    }}
                  >
                    DOWNLOAD PAYMENT NOTICE
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleDownload(2);
                    }}
                  >
                    DOWNLOAD INVOICE
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="outlined" onClick={handleSendEmail}>
                    SEND EMAIL
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      const companyIds = checkedEmails.map(
                        (company: any) => company.id
                      );
                      const venueIds = checkedEmails
                        .map((company: any) => company.venueId.split(","))
                        .flat(Infinity);
                      if (companyIds.length == 0) {
                        return showNotification({
                          message: "No company selected.",
                          severity: "error",
                        });
                      }
                      setListCompanyIds(companyIds);
                      setListVenueIds(venueIds);
                      setOpenPreviewModal(true);
                    }}
                  >
                    SEND TEST
                  </Button>
                </>
              )}
            </DialogActions>
          </Box>

          {!fetchDataModalLoading ? (
            <DialogContent
              style={modalType !== DOWNLOAD_TYPE ? { padding: "10px 0px" } : {}}
            >
              <TableContainer>
                <Table sx={{ borderCollapse: "collapse" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ border: "none" }}>
                        <Checkbox
                          checked={allChecked}
                          onChange={handleToggleAll}
                        />
                      </TableCell>
                      <TableCell sx={{ border: "none", paddingLeft: 0 }}>{`${
                        modalType === DOWNLOAD_TYPE
                          ? "Venue Name"
                          : "Company Name"
                      }`}</TableCell>
                      {modalType === DOWNLOAD_TYPE ? (
                        ""
                      ) : (
                        <>
                          <TableCell sx={{ border: "none" }}>
                            Venue Name
                          </TableCell>
                          <TableCell sx={{ border: "none" }}></TableCell>
                        </>
                      )}
                      <TableCell
                        sx={{
                          border: "none",
                          minWidth:
                            modalType === DOWNLOAD_TYPE ? "unset" : "360px",
                        }}
                      >
                        {modalType === DOWNLOAD_TYPE
                          ? "Total Amount"
                          : "Total pdf"}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {modalType === DOWNLOAD_TYPE
                          ? "Latest Exported at"
                          : "Lastest send"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modalTableList?.length > 0 ? (
                      modalTableList.map((venue: any, index: number) => {
                        const isDisabled =
                          modalType !== DOWNLOAD_TYPE &&
                          venue.manualResponse === 1;
                        const isError =
                          modalType !== DOWNLOAD_TYPE &&
                          `${venue.mailStatus}` === `${SENT_MAIL_STATUS.FAIL}`;
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              opacity: isDisabled ? 0.5 : 1,
                              pointerEvents: isDisabled ? "auto" : "auto",
                              cursor: isDisabled ? "not-allowed" : "pointer",
                              "& > td": isError ? { color: "red" } : {},
                            }}
                          >
                            <TableCell sx={{ border: "none", paddingY: "4px" }}>
                              <Checkbox
                                disabled={isDisabled}
                                checked={checked[index] ? true : false}
                                onChange={() => handleToggleCheckbox(index)}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                border: "none",
                                paddingY: "4px",
                                maxWidth: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingLeft: 0,
                              }}
                            >
                              <Tooltip title={venue.venueName}>
                                {venue.venueName}
                              </Tooltip>
                            </TableCell>
                            {modalType === DOWNLOAD_TYPE ? (
                              ""
                            ) : (
                              <>
                                <TableCell
                                  sx={{
                                    whiteSpace: "nowrap",
                                    border: "none",
                                    paddingY: "4px",
                                    maxWidth: "200px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <Tooltip title={venue.venue}>
                                    {venue.venue}
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    whiteSpace: "nowrap",
                                    border: "none",
                                    paddingY: "4px",
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={!!venue.manualResponse}
                                        onChange={(e: any) => {
                                          setCompanyId(venue.id);
                                          setIsChecked(e.target.checked);
                                        }}
                                      />
                                    }
                                    label="手動対応"
                                  />
                                </TableCell>
                              </>
                            )}
                            <TableCell
                              sx={{
                                border: "none",
                                paddingY: "4px",
                                minWidth:
                                  modalType === DOWNLOAD_TYPE
                                    ? "unset"
                                    : "360px",
                              }}
                            >
                              {modalType === DOWNLOAD_TYPE ? (
                                <Money
                                  amount={venue.totalAmount}
                                  currencyCode="JPY"
                                />
                              ) : (
                                <>
                                  <span>請求書（{venue.invoice})</span>
                                  <span style={{ padding: "0 16px" }}>
                                    お支払い通知書（{venue.payment_notice})
                                  </span>
                                  <span>アイテムセット({venue.item_set})</span>
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={{ border: "none", paddingY: "4px" }}>
                              {venue.exportedAt
                                ? format(
                                    new Date(venue.exportedAt),
                                    "yyyy-MM-dd HH:mm"
                                  )
                                : ""}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No record
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          ) : (
            <Box
              sx={{ height: "200px" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}
        </>
      </Dialog>
      <EmailPreviewModal
        open={openPreviewModal}
        onClose={handleClosePreviewModal}
        listCompanyIds={listCompanyIds}
        listVenueIds={listVenueIds}
        month={from}
      />
      <Dialog maxWidth={"sm"} open={Boolean(companyId)} onClose={() => {}}>
        <DialogContent style={{ width: 400 }}>
          <p style={{ textAlign: "center", margin: 0, fontSize: 22 }}>
            You sure not...
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCheckboxChange}
            color="primary"
          >
            Accept
          </Button>
          <Button
            onClick={async () => {
              setCompanyId(null);
              setIsChecked(null);
              handleModalOpen("sendEmail");
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? (
        <LinearProgress />
      ) : (
        <Overview venues={rows} date={yearMonth!} />
      )}
    </>
  );
}

const GET_VENUE_RESERVATIONS = gql`
  query VenueReservationsQuery(
    $offset: Int!
    $limit: Int!
    $from: String
    $until: String
    $search: String
    $hasPaidReservations: HasPaidReservationsInput
  ) {
    venues(
      offset: $offset
      limit: $limit
      search: $search
      hasPaidReservations: $hasPaidReservations
    ) {
      total
      records {
        name
        nameFurigana
        id
        company {
          id
          bankAccount {
            id
          }
        }
        venuePdfs {
          type
        }
        activities {
          id
          name
          priceTypes {
            id
            name
            unitType
            amount
            currencyCode
            contractedPrice
          }
          plan {
            id
            name
          }
          reservations(
            from: $from
            until: $until
            onlyPaidReservations: true
            onlyPrepaidReservations: true
          ) {
            id
            status
            datetime
            specialRequests
            commissionRate
            venuePaymentRate
            booking {
              id
              status
              familyName
              givenName
            }
            seatOption {
              price
            }
            items {
              id
              quantity
              costCurrencyCode
              contractedPrice
              priceType {
                contractedPrice
                currencyCode
                name
                id
              }
            }
            costs {
              commissionRate
              venuePaymentRate
              contractedAmount
              commission
              venuePaymentAmount
              currencyCode
              seatPrice
            }
          }
        }
      }
    }
  }
`;

const FETCH_ALL_FILE_ZIP_PDF_EXPORT = gql`
  query FetchAllFileZipPdfExport($month: DateTime!, $type: Int!) {
    fetchAllFileZipPdfExport(input: { month: $month, type: $type }) {
      filezip
      error
    }
  }
`;

const REPORT_VENUE_QUERY = gql`
  query ReportVenueQuery($from: DateTime!, $search: String) {
    getReportVenues(search: $search, from: $from) {
      records {
        url
        id
        venueId
        exportTime
        type
        totalAmount
        venue {
          id
          name
          nameFurigana
          email
          notificationEmails
          invoiceEmails
        }
      }
    }
  }
`;

const FETCH_COMPANY_VENUE = gql`
  query companiesToSendMail($search: String, $from: DateTime!) {
    companiesToSendMail(search: $search, from: $from) {
      records {
        company_id
        company_name
        venue_name
        venue_id
        venue_invoice_emails
        total_amount
        manual_response
        item_set
        invoice
        payment_notice
        mail_status
        send_time
      }
    }
  }
`;

const SAVE_COMPANY_MUTATION = gql`
  mutation SaveCompanyMutation($input: BasicCompanyManualResponseInput!) {
    updateManualResponseStatus(input: $input) {
      success
      error
    }
  }
`;

const SEND_EMAIL = gql`
  query sendVenueNoticeEmails(
    $companyIds: [String!]
    $venueIds: [String!]
    $month: DateTime!
  ) {
    sendVenueNoticeEmails(
      companyIds: $companyIds
      venueIds: $venueIds
      month: $month
    ) {
      success
    }
  }
`;

const FETCH_FILE_ZIP_PDF_EXPORT = gql`
  query FetchFileZipPdfExport(
    $venueIds: [String!]!
    $month: DateTime!
    $type: Int!
  ) {
    fetchFileZipPdfExport(
      input: { venueIds: $venueIds, month: $month, type: $type }
    ) {
      filezip
      error
    }
  }
`;

const exportBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100);
};
