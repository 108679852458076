import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";

import TextField from "../../../../components/TextField";
import Button from "../../../../components/Button2";
import Items from "./Items";
import { ActivityReservationType } from "../ActionButtons/types";
import { ItemType as ActivityReservationItemType } from "../../View/ActivityReservations/Items/Item";
import Answers from "./Answers";
import ConfirmReservationButton from "../ActionButtons/ConfirmReservationButton";
import RejectReservationButton from "../ActionButtons/RejectReservationButton";
import CancelReservationButton from "../ActionButtons/CancelReservationButton";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useNotifications } from "../../../../components/Notification";
import { ConfirmationSelect } from "../ConfirmationSelect";
import { EditStatus, ReservationStatus } from "../../../../types";
import { useViewBookingContext } from "..";
import { DataEditItem, SAVE_BOOKING_EDIT_MUTATION } from "../Basic";
import convertSpaceFullWidthToHalfWidth from "../../../../hooks/useConvertSpaceFullwidthToHalfwidth";

function ActivityReservation(props: {
  bookingId: string;
  data: ActivityReservationType;
  refetch: () => Promise<void>;
  paymentStatus: string;
  canEditItems: boolean;
}) {
  const {
    seatOption,
    priceTypes,
    newPlan,
    seatPrice,
  } = useViewBookingContext();
  const reservation = props.data;
  const paymentStatus = props.paymentStatus;

  const [editActivityItems] = useMutation(EDIT_ACTIVITY_ITEMS);
  const [saveBookingEdit] = useMutation(SAVE_BOOKING_EDIT_MUTATION);

  const [activityItems, setActivityItems] = useState(reservation.items);

  const [reservationStatus, setReservationStatus] = useState<ReservationStatus>(
    reservation.status as ReservationStatus
  );

  const { showNotification } = useNotifications();

  const handleEditBookingActivity = useCallback(async () => {
    for (const item of activityItems) {
      const result = await editActivityItems({
        variables: {
          activityReservationItemId: item.id,
          activityReservationId: reservation.id,
          quantity: item.quantity,
          reservationStatus,
        },
      });
      const { success, error } = result.data.editBookingActivityItem;
      if (success) {
        showNotification({
          message: `Booking was edited`,
          severity: "success",
        });
        await props.refetch();
      } else {
        showNotification({
          message: `Editing booking failed! ${error}`,
          severity: "error",
        });
      }
    }

    const dataEdits: DataEditItem[] = [];

    if (reservationStatus !== reservation.status) {
      dataEdits.push({
        fieldName: "Reservation status",
        newValue: JSON.stringify({
          text: reservationStatus,
        }),
        oldValue: JSON.stringify({ text: reservation.status }),
        editStatus: EditStatus.TOUCHED,
      });
    }

    reservation.items.forEach((item) => {
      const activityItem = activityItems.find(
        (obj: ActivityReservationItemType) => obj.id === item.id
      );

      if (activityItem && activityItem.quantity !== item.quantity) {
        dataEdits.push({
          fieldName: "Course Quantity",
          newValue: JSON.stringify(activityItem),
          oldValue: JSON.stringify(item),
          editStatus: EditStatus.TOUCHED,
        });
      }
    });

    if (dataEdits.length > 0) {
      await saveBookingEdit({
        variables: {
          input: { editData: dataEdits, bookingId: props.bookingId },
        },
      });
    }
  }, [
    activityItems,
    reservationStatus,
    editActivityItems,
    props,
    reservation.id,
    showNotification,
  ]);

  let actionButtons;

  if (paymentStatus === "PAID" && reservation.status === "NOT_YET_RESERVED") {
    actionButtons = (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <ConfirmReservationButton reservation={reservation} />
        </Grid>
        <Grid item xs={6}>
          <RejectReservationButton reservation={reservation} />
        </Grid>
      </Grid>
    );
  } else if (reservation.status === "RESERVED") {
    actionButtons = (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CancelReservationButton reservation={reservation} />
        </Grid>
      </Grid>
    );
  } else {
    actionButtons = <></>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Reservation Info" action={actionButtons} />
          <Divider />
          <CardContent>
            <TextField
              readOnly={true}
              label={"Reservation ID"}
              value={reservation.id}
            />
            <ConfirmationSelect
              label={"Reservation Status"}
              value={reservationStatus}
              setValue={setReservationStatus}
              values={Object.values(ReservationStatus)}
              fontColor={ReservationStatusColor[reservationStatus]}
            />
            {(reservationStatus === ReservationStatus.RESERVED ||
              reservation.reservedAt) && (
              <TextField
                readOnly={true}
                label={"Reserved At"}
                value={reservation.reservedAt || "determined on save"}
              />
            )}
            {reservationStatus === ReservationStatus.REJECTED && (
              <TextField
                readOnly={true}
                label={"Rejected At"}
                value={reservation.rejectedAt || "determined on save"}
              />
            )}
            {reservationStatus === ReservationStatus.CANCELLED && (
              <TextField
                readOnly={true}
                label={"Cancelled At"}
                value={reservation.cancelledAt || "determined on save"}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <TextField
              readOnly={true}
              label={"Venue"}
              value={`${newPlan?.venue?.name ?? reservation.venue.name}${
                newPlan?.venue?.nameFurigana
                  ? ` (${convertSpaceFullWidthToHalfWidth(
                      newPlan.venue.nameFurigana
                    )})`
                  : reservation.venue.nameFurigana
                  ? ` (${convertSpaceFullWidthToHalfWidth(
                      reservation.venue.nameFurigana
                    )})`
                  : ""
              }`}
            />
            <TextField
              readOnly={true}
              label={"Seat Option"}
              value={
                seatOption ? seatOption.title : reservation.seatOption.title
              }
            />
            {(reservation.seatOption.price !== undefined &&
              reservation.seatOption.price > 0 &&
              !seatOption) ||
            seatPrice > 0 ? (
              <TextField
                readOnly={true}
                label={"Seat Option Price"}
                value={seatPrice ? seatPrice : reservation.seatOption.price}
              />
            ) : null}
            <TextField
              readOnly={true}
              label={"Datetime"}
              value={reservation.datetime}
            />
            <TextField
              readOnly={true}
              label={"Special Requests"}
              value={reservation.specialRequests || ""}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Items
          items={priceTypes ? priceTypes : activityItems}
          reservation={reservation}
          setItems={setActivityItems}
          readOnly={!props.canEditItems || Boolean(newPlan)}
        />
      </Grid>
      {reservation.answers && (
        <Grid item xs={12}>
          <Answers answers={reservation.answers} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Card style={{ padding: "10px" }}>
          <Button
            disabled={Boolean(newPlan)}
            onClick={handleEditBookingActivity}
          >
            Save
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ActivityReservation;

const ReservationStatusColor: { [key: string]: string } = {
  NOT_YET_RESERVED: "#000000",
  RESERVED: "#4caf50",
  REJECTED: "#f44336",
  CANCELLED: "#9c27b0",
};

const EDIT_ACTIVITY_ITEMS = gql`
  mutation EDIT_ACTIVITY_ITEMS(
    $activityReservationItemId: ID!
    $quantity: Int!
    $activityReservationId: String
    $reservationStatus: ReservationStatus
  ) {
    editBookingActivityItem(
      input: {
        activityReservationItemId: $activityReservationItemId
        quantity: $quantity
        activityReservationId: $activityReservationId
        reservationStatus: $reservationStatus
      }
    ) {
      success
      error
    }
  }
`;
