import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useMemo, useState } from "react";
import Select from "../../../components/Select";
import { Tag, TagCategory } from "../../../types";
import useTags from "../../../hooks/useTags";

type EditTagProps = {
  selectedTag?: Tag;
  tagNames: Set<string>;
  tagCategories: TagCategory[];
  onSaveCallback: () => void;
};
export default function EditTag({
  selectedTag,
  tagNames,
  tagCategories,
  onSaveCallback,
}: EditTagProps) {
  const [name, setName] = useState(selectedTag?.name ?? "");
  const [slug, setSlug] = useState(selectedTag?.slug ?? "");
  const [groupName, setGroupName] = useState(selectedTag?.groupName ?? "");
  const [display, setDisplay] = useState(selectedTag?.display);
  const trimmedName = useMemo(() => name.trim(), [name]);

  const [tagCategoryId, setTagCategoryId] = useState<TagCategory["id"]>(
    selectedTag?.tagCategory.id ?? tagCategories[0].id
  );

  const options = useMemo(
    () =>
      tagCategories.map((tagCategory) => ({
        id: String(tagCategory.id),
        name: tagCategory.name,
      })),
    [tagCategories]
  );

  const validateSlug = (slug: string) => {
    const regex = /^[a-zA-Z0-9-]+$/;
    return regex.test(slug);
  };

  const saveError = useMemo(() => {
    if (!trimmedName) return "Need a name";
    if (
      trimmedName === selectedTag?.name &&
      tagCategoryId === selectedTag?.tagCategory.id &&
      slug === selectedTag?.slug &&
      display === selectedTag?.display &&
      groupName === selectedTag?.groupName
    )
      return "No changes";

    if (slug && !validateSlug(slug)) {
      return "Slug invalid";
    }
    if (trimmedName !== selectedTag?.name && tagNames.has(trimmedName))
      return "Tag name already exists";
  }, [
    trimmedName,
    tagNames,
    tagCategoryId,
    slug,
    display,
    groupName,
    selectedTag?.name,
    selectedTag?.slug,
    selectedTag?.tagCategory,
    selectedTag?.display,
    selectedTag?.groupName,
  ]);

  const {
    editTag,
    editTagResponse: { loading },
  } = useTags();

  return (
    <Stack direction="column" gap={1}>
      <TextField
        label="name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        fullWidth
      />
      <TextField
        label="slug"
        value={slug}
        onChange={(event) => setSlug(event.target.value)}
        fullWidth
      />
      <TextField
        label="group"
        value={groupName}
        onChange={(event) => setGroupName(event.target.value)}
        fullWidth
      />
      <Select
        value={String(tagCategoryId)}
        label="category"
        options={options}
        onChange={(value) => setTagCategoryId(Number(value))}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={display}
            onChange={({ target: { checked } }) => setDisplay(checked)}
          />
        }
        label="Display"
        style={{ float: "left" }}
      />
      <Typography
        sx={{ color: "red", minHeight: "1.5em", textAlign: "center" }}
      >
        {saveError}
      </Typography>

      <Button
        variant="contained"
        endIcon={loading ? <CircularProgress size={22} /> : undefined}
        disabled={!!saveError || loading}
        onClick={async () => {
          const { errors } = await editTag({
            variables: {
              id: selectedTag?.id,
              name: trimmedName,
              slug,
              groupName: groupName,
              display: display ?? false,
              tagCategoryId,
            },
          });
          if (!errors?.[0]) onSaveCallback();
        }}
      >
        Save
      </Button>
    </Stack>
  );
}
