import { useState, useEffect } from "react";
import {
  AllWeekdays,
  AllHolidays,
} from "../../../../../components/Schedule/NewRecurrenceRule";

import { Button, Typography, ButtonGroup } from "@mui/material";
import { SeatOptionPriceJson } from "./SeatOptionsForm";

type SeatOptionAvailabilityFormProps = {
  price: SeatOptionPriceJson;
  priceIdx: number;
  disabledDays?: string[];
  onChange: (
    fieldName: keyof SeatOptionPriceJson,
    index: number,
    value: any
  ) => void;
};

export default function SeatOptionAvailabilityForm({
  price,
  priceIdx,
  disabledDays,
  onChange,
}: SeatOptionAvailabilityFormProps) {
  const [weekdays, setWeekdays] = useState<string[]>(price.weekdays ?? []);
  const [holidays, setHolidays] = useState<string[]>(price.holidays ?? []);

  useEffect(() => {
    onChange("weekdays", priceIdx, weekdays);
  }, [weekdays]);

  useEffect(() => {
    onChange("holidays", priceIdx, holidays);
  }, [holidays]);

  return (
    <div>
      <Typography>Weekdays:</Typography>

      <ButtonGroup size="small" color="primary">
        {AllWeekdays.map((w, idx) => (
          <Button
            variant={weekdays.includes(w) ? "contained" : "outlined"}
            key={`w_${priceIdx}_${idx}`}
            onClick={() => {
              setWeekdays((weekdays) =>
                weekdays.includes(w)
                  ? weekdays.filter((w2) => w2 !== w)
                  : weekdays.concat(w)
              );
            }}
            disabled={
              holidays.length > 0 ||
              (!weekdays.includes(w) && disabledDays?.includes(w))
            }
          >
            {w}
          </Button>
        ))}
        {AllHolidays.map((h, idx) => (
          <Button
            variant={holidays.includes(h) ? "contained" : "outlined"}
            key={`h_${priceIdx}_${idx}`}
            onClick={() =>
              setHolidays((holidays) =>
                holidays.includes(h)
                  ? holidays.filter((h2) => h2 !== h)
                  : holidays.concat(h)
              )
            }
            disabled={
              weekdays.length > 0 ||
              (!holidays.includes(h) && disabledDays?.includes(h))
            }
          >
            {h}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}
