import DataTable from "../../../components/DataTable";
import { Card, CardContent, Typography } from "@mui/material";
import type { ReservationWithCosts } from "./types";
import DownloadCSV from "./DownloadCSV";
import Money from "./Money";
import { DownloadPaymentNotice } from "./DownloadPaymentNotice";
import { DownloadInvoiceOrItemSet } from "./DownloadInvoice";
import { Venue } from "../../../types";
import { format } from "date-fns";
import convertSpaceFullWidthToHalfWidth from "../../../hooks/useConvertSpaceFullwidthToHalfwidth";

const ReservationRows = [
  { name: "Status", field: "status" },
  {
    name: "Plan",
    render: (reservation: ReservationWithCosts) => (
      <span>{reservation.planName}</span>
    ),
  },
  {
    name: "Time",
    render: (reservation: ReservationWithCosts) => (
      <span>{format(new Date(reservation.datetime), "yyyy-MM-dd HH:mm")}</span>
    ),
  },
  {
    name: "Booking",
    render: (reservation: ReservationWithCosts) => (
      <span>
        {reservation.booking.familyName} {reservation.booking.givenName}
      </span>
    ),
  },
  {
    name: "Attendees",
    render: (reservation: ReservationWithCosts) => (
      <ul style={{ listStyle: "none", padding: 0 }}>
        {reservation.items.map((item) => (
          <li>
            {item.priceType.name} : {item.quantity}
          </li>
        ))}
      </ul>
    ),
  },
  {
    name: "Base Cost",
    render: (reservation: ReservationWithCosts) => (
      <Money
        amount={reservation.costs.contractedAmount}
        currencyCode={reservation.costs.currencyCode}
      />
    ),
  },
  {
    name: "Cancellation",
    render: (reservation: ReservationWithCosts) =>
      reservation.cancelledAt ? (
        <span>
          {reservation.cancelledAt} ({reservation.costs.venuePaymentRate}% to be
          paid)
        </span>
      ) : (
        <span />
      ),
  },
  {
    name: "Final cost",
    render: (reservation: ReservationWithCosts) => (
      <Money
        amount={reservation.costs.venuePaymentAmount}
        currencyCode={reservation.costs.currencyCode}
      />
    ),
  },
];

const checkVenePdf = (type: number, venuePdfs?: { type: number }[]) => {
  if (!venuePdfs) return true;
  return venuePdfs.find((pdf) => pdf.type == type);
};

export default function VenueCostsOverview(props: {
  venues: (Venue & { reservations: ReservationWithCosts[] })[];
  date: Date;
}) {
  const filteredVenues = [...props.venues].map((venue) => ({
    ...venue,
    reservations: venue
      .reservations!.filter(
        (reservation: any) => reservation.paymentMethod !== "ON_SITE_PAYMENT"
      )
      .sort((a, b) => a.datetime.localeCompare(b.datetime)),
  }));

  return (
    <>
      {filteredVenues.map((venue, index) => {
        const hasPaidReservations = venue.reservations[0] !== undefined;

        return (
          <Card style={{ marginBottom: "10px" }} key={index}>
            <CardContent>
              <Typography>
                {venue.name}
                {venue.nameFurigana &&
                  ` (${convertSpaceFullWidthToHalfWidth(venue.nameFurigana)})`}
              </Typography>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <DownloadCSV
                  reservations={venue.reservations}
                  venue={venue}
                  date={props.date}
                  hasPaidReservations={hasPaidReservations}
                />
                <DownloadPaymentNotice
                  venue={venue}
                  date={props.date}
                  hasPaidReservations={hasPaidReservations}
                  disabled={!checkVenePdf(1, venue.venuePdfs)}
                />
                <DownloadInvoiceOrItemSet
                  venue={venue}
                  date={props.date}
                  isItemSet={false}
                  disabled={!checkVenePdf(2, venue.venuePdfs)}
                />
                <DownloadInvoiceOrItemSet
                  venue={venue}
                  date={props.date}
                  isItemSet={true}
                  disabled={!checkVenePdf(3, venue.venuePdfs)}
                />
              </div>
              <DataTable rows={venue.reservations} columns={ReservationRows} />
              <Typography>
                <Money
                  amount={venue.reservations.reduce(
                    (total, r) => r.costs.venuePaymentAmount + total,
                    0
                  )}
                  currencyCode={venue.reservations[0]?.costs.currencyCode}
                />
              </Typography>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
}
