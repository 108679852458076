import { gql, useQuery } from "@apollo/client";
import useGetPartnerEdits from "../useGetPartnerEdits";
import { useState, useEffect, useMemo } from "react";
import EditCard from "../cards/EditCard";
import {
  FaqData,
  HighlightData,
  InclusionData,
  OriginalData,
  PaidItemData,
} from "../types";
import { CoursePhoto, PartnerPlanEdit, Plan } from "../../../../../types";
import { Paper } from "@mui/material";

type PendingEditListProps = Pick<Plan, "id">;
export default function PendingEditList({ id: planId }: PendingEditListProps) {
  const { data: originalData, loading: originalLoading } = useQuery<
    OriginalPlanQueryResponse,
    OriginalPlanQueryInput
  >(ORIGINAL_PLAN_QUERY, {
    variables: {
      planId,
    },
  });

  const original = useMemo(() => originalData?.plan, [originalData]);
  const activityId = original?.activities[0].id ?? "";

  const [inclusions, setInclusions] = useState<InclusionData[]>([]);
  const [faqs, setFaqs] = useState<FaqData[]>([]);
  const [paidItem, setPaidItem] = useState<PaidItemData[]>([]);
  const [highlights, setHighlights] = useState<HighlightData[]>([]);
  // const [coursePhotos, setCoursePhotos] = useState<CoursePhoto[]>([]);

  useEffect(() => {
    if (originalData === undefined) return;

    setInclusions(originalData.plan.inclusions!);
    setFaqs(originalData.plan.faqs);
    setHighlights(originalData.plan.highlights);
    // setCoursePhotos(originalData.plan.activities[0].coursePhotos ?? []);
  }, [originalData]);

  const { data, loading } = useGetPartnerEdits({
    planId,
  });

  const [partnerEdits, setPartnerEdits] = useState<PartnerPlanEdit[]>([]);

  useEffect(() => {
    if (data === undefined) return;
    setPartnerEdits(data.partnerEdits.records);
  }, [data]);

  return (
    <div>
      {loading || originalLoading ? (
        <></>
      ) : partnerEdits.length > 0 ? (
        partnerEdits.map((edit) => {
          return (
            <Paper sx={{ marginBottom: "1rem" }} key={edit.id}>
              <EditCard
                partnerEdits={partnerEdits}
                setPartnerEdits={setPartnerEdits}
                edit={edit}
                originalData={original}
                mutationProps={{
                  planId,
                  originalData: original,
                  activityId,
                  inclusions,
                  editStatus: edit.editStatus,
                  setInclusions,
                  faqs,
                  setFaqs,
                  highlights,
                  setHighlights,
                  paidItem,
                  setPaidItem,
                }}
              />
            </Paper>
          );
        })
      ) : (
        <h1>no edits</h1>
      )}
    </div>
  );
}

const ORIGINAL_PLAN_QUERY = gql`
  query originalPlanQuery($planId: ID!) {
    plan(id: $planId) {
      id
      name
      subtitle
      remarks
      description
      inclusions {
        category
        items {
          id
          title
          description
          imageUrl
          iconName
        }
      }
      activities {
        id
        name
        courseMenu
        coursePhotos {
          id
          src
        }
      }
      highlights {
        index
        title
        details
        exclusive
        media {
          src
          type
        }
      }
      faqs {
        id
        question
        answer
        imageUrl
      }
      planPaidItems {
        id
        name
        description
        status
        imageUrl
        price
      }
    }
  }
`;

type OriginalPlanQueryInput = {
  planId: string;
};

type OriginalPlanQueryResponse = {
  plan: OriginalData;
};
