import { gql } from "@apollo/client";
import { Tag } from "../../../types";

export type EditTagMutationInput = Partial<Pick<Tag, "id" | "slug">> &
  Pick<Tag, "name" | "tagCategoryId" | "display" | "groupName">;

export type EditTagMutationResponse = {
  editTag: {
    tag: Tag;
    originalTag: Tag;
  };
};

export const EDIT_TAG_MUTATION = gql`
  mutation EditTagMutation(
    $id: Int
    $name: String!
    $slug: String
    $display: Boolean
    $groupName: String
    $tagCategoryId: Int!
  ) {
    editTag(
      id: $id
      name: $name
      slug: $slug
      display: $display
      groupName: $groupName
      tagCategoryId: $tagCategoryId
    ) {
      tag {
        id
        name
        slug
        tagCategoryId
      }
      originalTag {
        tagCategoryId
      }
    }
  }
`;
