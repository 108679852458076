import { gql } from "@apollo/client";
import { TagCategory } from "../../../types";

export type GetTagCategoriesAndTagsQueryInput = {
  search?: string;
};

export type GetTagCategoriesAndTagsQueryResponse = {
  tagCategories: TagCategory[];
};

export const GET_TAG_CATEGORIES_AND_TAGS_QUERY = gql`
  query TagCategoriesAndTagsQuery {
    tagCategories {
      id
      adminName
      name
      createdAt
      tags {
        id
        name
        slug
        display
        groupName
      }
    }
  }
`;
