import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import queryString from "query-string";

import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button2";
import { IconButton } from "@mui/material";
import DeleteVenueDialog from "./Dialogs/DeleteVenue";
import DownloadIcon from "@mui/icons-material/Download";
import convertSpaceFullWidthToHalfWidth from "../../../hooks/useConvertSpaceFullwidthToHalfwidth";
import {
  TripleState,
  TripleStateCheckbox
} from "../../Plans/components/BasicInfoForm/MultiStateCheckbox/TripleStateCheckbox";
import {PartnerEditNotificationStack} from "../../Plans/List/components/PartnerEditNotificationStack";

export default function VenuesList() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const pageSize = 10;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );
  const [venueToBeRemoved, setVenueToBeRemoved] = useState<Venue | null>(null);

  const [listTrashVenues, setListTrashUnTrashedVenues] = useState<TripleState>(
    () => {
      const trashQuery = parsedQueryString.trashed;
      if (trashQuery === "true") return true;
      if (trashQuery === "false") return false;
      return undefined;
    }
  );

  useEffect(updateUrl, [page, search, navigate, listTrashVenues]);

  const { data, loading, error, fetchMore } = useQuery(VENUES_QUERY, {
    variables: {
      offset: 0,
      limit: 10,
      search: search,
      trashed: listTrashVenues,
    },
  });

  const [
    getVenueData,
    { loading: loadingDownload },
  ] = useLazyQuery<GetVenueDataResponse>(GET_VENUE_DATA_EMAIL, {
    fetchPolicy: "network-only",
    onCompleted: (data) =>
      window.open(data.exportVenueDataEmailToCloudStorage, "_blank"),
  });

  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderEditVenue },
    { name: "ID", field: "id" },
    { name: "Name", render: renderName },
    { name: "Plans", render: renderPlans },
    { name: ".", render: renderDeleteVenue },
  ];
  const rows = data ? data.venues.records : undefined;

  return (
    <>
      <DataTable
        loading={loading || loadingDownload}
        columns={columns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows: data ? data.venues.total : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
        topBarExtras={renderTopBarExtras(
          listTrashVenues,
          setListTrashUnTrashedVenues,
        )}
      />
      <DeleteVenueDialog
        open={venueToBeRemoved !== null}
        onClose={() => setVenueToBeRemoved(null)}
        venue={venueToBeRemoved}
      />
    </>
  );

  function updateUrl() {
    const urlParams = {
      page: page + 1,
      search: search,
      trashed: listTrashVenues,
    };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderTopBarExtras(
    listTrashVenues: TripleState,
    setListTrashUnTrashedVenues: (listTrashVenues: TripleState) => void,
  ) {
    return (
      <div
        style={{
          marginLeft: "0.5rem",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TripleStateCheckbox
            label="trashed"
            state={listTrashVenues}
            setState={(state) => {
              setPage(0);
              setListTrashUnTrashedVenues(state);
            }}
          />
        </div>

        <Button
          style={{backgroundColor: "white", color: "#c8a063"}}
          variant="contained"
          component={Link}
          to="/venues/new"
          startIcon={<AddIcon/>}
        >
          New
        </Button>
        <Button
          onClick={() => getVenueData()}
          variant="contained"
          startIcon={<DownloadIcon/>}
        >
          Export Email
        </Button>
      </div>
      )
    ;
  }

  function renderEditVenue(venue: Venue) {
    return (
      <Button variant="contained" component={Link} to={`/venues/${venue.id}`}>
        <EditIcon/>
      </Button>
    );
  }

  function renderName(venue: Venue) {
    if (venue?.nameFurigana)
      return (
        <>
          {venue.name} ({convertSpaceFullWidthToHalfWidth(venue.nameFurigana)})
        </>
      );
    return <>{venue.name}</>;
  }

  function renderPlans(venue: Venue) {
    return <>{venue.plans.length}</>;
  }

  function renderDeleteVenue(venue: Venue) {
    return (
      <IconButton onClick={() => setVenueToBeRemoved(venue)}>
        <DeleteIcon/>
      </IconButton>
    );
  }
}

const VENUES_QUERY = gql`
  query VenuesQuery($offset: Int!, $limit: Int!, $search: String, $trashed: Boolean) {
    venues(offset: $offset, limit: $limit, search: $search, trashed: $trashed) {
      total
      records {
        id
        name
        nameFurigana
        plans {
          id
          name
        }
        publishedPlans {
          id
          name
        }
      }
    }
  }
`;

type GetVenueDataResponse = { exportVenueDataEmailToCloudStorage: string };
const GET_VENUE_DATA_EMAIL = gql`
  query GetVenueDataEmail {
    exportVenueDataEmailToCloudStorage
  }
`;

export interface Venue {
  id: string;
  name: string;
  nameFurigana: string;
  plans: Array<{
    id: string;
    name: string;
  }>;
  publishedPlans: Array<{
    id: string;
    name: string;
  }>;
}
