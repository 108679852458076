import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "../../../components/Notification";
import { UnitType } from "../../../types";
import { getPlatform } from "../../../helpers/get-user-platform";

const useStyles = makeStyles({
  container: {
    textAlign: "center",
    backgroundColor: "#ffffff",
  },
  header: {
    backgroundColor: "#C8A063",
    color: "#ffffff",
  },
  button: {
    backgroundColor: "#C8A063",
    color: "#ffffff",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#C8A063",
    },
  },
  boxed: {
    marginLeft: "4rem",
    marginRight: "4rem",
    background: "#fff",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "bold",
    padding: "25px",
    boxShadow: "inset #C8A063 0 0 0 5px",
  },
  list: {
    columns: "5 auto",
    listStyleType: "none",
    marginLeft: "2rem",
    marginRight: "6rem",
    maxWidth: "1200px",
    textAlign: "start",
    columnGap: "5%",
  },
});

const REQUIRED_KEYS = [
  "name",
  "areaName",
  "description",
  "subtitle",
  "cancellationPolicyDescription",
  "paymentMethodDescription",
  "conciergeName",
  "conciergeComment",
  "basePrice",
  "exclusive",
  "onSitePayment",
  "amazonPay",
  "stripe",
  "inclusionCategory1",
  "inclusionTitles1",
  "inclusionDescriptions1",
  "activityName",
  "venueName",
  "detailsForVenue",
  "courseMenu",
  "offsetMinutes",
  "offsetHours",
  "commissionRate",
  "onSiteCommissionRate",
];

const OPTIONAL_KEYS = [
  "remarks",
  "basePriceName",
  "tagIds",
  "locationName",
  "inclusionIconNames1",
  "inclusionIconNames2",
  "inclusionCategory2",
  "inclusionTitles2",
  "inclusionDescriptions2",
  "inclusionIconNames3",
  "inclusionCategory3",
  "inclusionTitles3",
  "inclusionDescriptions3",
  "highlight1",
  "highlight2",
  "highlight3",
  "questionsByVenue",
  "priceType1",
  "priceType2",
  "priceType3",
  "seatOption",
];

function getMissingKeys(
  csvDataArray: Record<string, any>[],
  requiredKeys: string[],
  optionalKeys: string[]
): { missingKeys: string[]; unexpectedKeys: string[] } {
  const missingKeys: string[] = [];
  const unexpectedKeys: string[] = [];

  // Iterate through each object of the csvDataArray
  for (const originalObject of csvDataArray) {
    // Filter out any keys that are not specified as required
    const filteredObj = Object.keys(originalObject)
      .filter((key) => {
        // Keep track of keys that are neither required nor optional (= should not be a column)
        if (![...requiredKeys, ...optionalKeys].includes(key))
          unexpectedKeys.push(key);
        return requiredKeys.includes(key);
      })
      .reduce((obj: any, key: string) => {
        obj[key] = originalObject[key];
        return obj;
      }, {});

    // Check if the filtered object contains all of the specified keys
    if (Object.keys(filteredObj).length !== requiredKeys.length) {
      const missing = requiredKeys.filter(
        (key) => !Object.keys(filteredObj).includes(key)
      );
      missingKeys.push(...missing);
    }
  }

  // Return the array of unique missing keys
  return {
    missingKeys: [...new Set(missingKeys)],
    unexpectedKeys: [...new Set(unexpectedKeys)],
  };
}

function checkBooleanFields(csvDataArray: Record<string, any>[]) {
  return csvDataArray.every(
    (planInput) =>
      (planInput.stripe === "" || planInput.stripe === "1") &&
      (planInput.amazonPay === "" || planInput.amazonPay === "1") &&
      (planInput.onSitePayment === "" || planInput.onSitePayment === "1") &&
      (planInput.exclusive === "" || planInput.exclusive === "1")
  );
}

export default function ImportPlan() {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [array, setArray] = useState<Array<Record<string, string>>>([]);
  const [importPlans] = useMutation(IMPORT_PLANS_MUTATION);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotifications();

  const fileReader = new FileReader();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0]);
  };

  function resetImportProcess(e: React.FormEvent<HTMLFormElement>) {
    // reset input file, so user can attach new file
    setFile(undefined);
    // @ts-ignore
    e.target[0].value = null;

    setLoading(false);
  }

  function generatePlanHighlights(
    plan: Record<string, any>
  ): Record<string, any | null> {
    const planHighlight1 = plan.highlight1.split(";");
    const planHighlight2 = plan.highlight2.split(";");
    const planHighlight3 = plan.highlight3.split(";");
    const combinedHighlights = [planHighlight1, planHighlight2, planHighlight3];

    const planHighlights = combinedHighlights
      .map((highlight, index) => {
        // if the column has content, splitting it provided a meaningful array
        if (highlight.length > 1) {
          return {
            index: index + 1,
            title: String(highlight[0]),
            details: String(highlight[1]),
            exclusive: Boolean(highlight[2]) || false,
          };
        }
        return null;
      })
      .filter((highlight) => highlight !== null);

    return planHighlights;
  }

  function generateActivity(
    plan: Record<string, any>
  ): Record<string, any | null> {
    const questions = plan.questionsByVenue
      .split(";")
      .filter((question: string) => question !== "");
    const activity = {
      name: plan.activityName,
      venueName: plan.venueName,
      detailsForVenue: plan.detailsForVenue,
      courseMenu: plan.courseMenu,
      offset: {
        hours: plan.offsetHours || 0,
        minutes: plan.offsetMinutes || 0,
      },
      contract: {
        commissionRate: plan.commissionRate / 100,
        onSiteCommissionRate: plan.onSiteCommissionRate / 100,
      },
      questionsByVenue: questions.length > 0 ? [...questions] : [],
    };
    return activity;
  }

  function generateActivityPriceTypes(
    plan: Record<string, any>
  ): Record<string, any | null> {
    const priceType1 = plan.priceType1.split(";");
    const priceType2 = plan.priceType2.split(";");
    const priceType3 = plan.priceType3.split(";");
    const combinedPriceTypes = [priceType1, priceType2, priceType3];

    const activityPriceTypes = combinedPriceTypes
      .map((priceType, index) => {
        // if the column has content, splitting it provided a meaningful array
        if (priceType.length > 1) {
          return {
            id: uuid(),
            index: index + 1,
            name: String(priceType[0]),
            unitType: Object.keys(UnitType)[
              Object.values(UnitType).indexOf(priceType[1])
            ],
            amount: Number(priceType[2]),
            contractedPrice: Number(priceType[3]),
            minAttendees: Number(priceType[4]),
            maxAttendees: Number(priceType[5]) || null,
            active: true,
            currencyCode: "JPY",
          };
        }
        return null;
      })
      .filter((priceType) => priceType !== null);

    return activityPriceTypes;
  }

  function generateActivitySeatOptions(
    plan: Record<string, any>
  ): Record<string, any | null> {
    const seatOption1 = plan.seatOption.split(";");
    const combinedSeatOptions = [seatOption1];

    const activitySeatOptions = combinedSeatOptions
      .map((seatOption) => {
        // if the column has content, splitting it provided a meaningful array
        if (seatOption.length > 1) {
          return {
            id: uuid(),
            title: String(seatOption[0]),
            details: String(seatOption[1]),
            minAttendees: Number(seatOption[2]),
            maxAttendees: Number(seatOption[3]),
            price: Number(seatOption[4]) || 0,
            photos: [],
            active: true,
          };
        }
        return null;
      })
      .filter((seatOption) => seatOption !== null);

    return activitySeatOptions;
  }

  function csvToArray(inputString: string) {
    const platform = getPlatform().toLowerCase();
    const isWindows = /win/.test(platform);

    // for unix users it needs to be index of \n, for windows \r\n because of different CSV formats on saving
    const csvHeader = isWindows
      ? inputString
          .slice(0, inputString.indexOf("\r\n"))
          .split(",")
          .map((item) => item.trim())
      : inputString
          .slice(0, inputString.indexOf("\n"))
          .replace(/\r/g, "")
          .split(",")
          .map((item) => item.trim());

    let previousCharacter = "",
      row = [""],
      resultingMultiDimensionalArray = [row],
      columnIndex = 0,
      rowIndex = 0,
      isQuotedString = !0,
      currentCharacter;

    for (currentCharacter of inputString) {
      if ('"' === currentCharacter) {
        if (isQuotedString && currentCharacter === previousCharacter)
          row[columnIndex] += currentCharacter;
        isQuotedString = !isQuotedString;
      } else if ("," === currentCharacter && isQuotedString)
        currentCharacter = row[++columnIndex] = "";
      else if ("\n" === currentCharacter && isQuotedString) {
        if ("\r" === previousCharacter)
          row[columnIndex] = row[columnIndex].slice(0, -1);
        row = resultingMultiDimensionalArray[++rowIndex] = [
          (currentCharacter = ""),
        ];
        columnIndex = 0;
      } else row[columnIndex] += currentCharacter;
      previousCharacter = currentCharacter;
    }

    const csvRows = resultingMultiDimensionalArray.map((rowContent) =>
      rowContent.join(",")
    );

    // filter out the rows with only location key
    const newArray = csvRows
      .map((rowContent, i) => {
        // don't return first row (headers) and empty rows
        if (!rowContent || rowContent === " " || i === 0) return [];

        const values = rowContent.split(",");

        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {} as Record<string, any>);

        return obj;
      })
      .filter((planObj) => Object.keys(planObj).length > 1);

    return newArray;
  }

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (file) {
      fileReader.onload = function (event) {
        if (event && event.target) {
          const text = event.target.result as string;
          const csvDataArray = csvToArray(text);

          // check if column names match the expected format
          const validatedKeys = getMissingKeys(
            csvDataArray,
            REQUIRED_KEYS,
            OPTIONAL_KEYS
          );

          const isBooleanCompatibleInput = checkBooleanFields(csvDataArray);

          if (validatedKeys.missingKeys.length > 0) {
            showNotification({
              message: `The uploaded CSV file does not contain all required columns: ${validatedKeys.missingKeys}`,
              severity: "error",
            });
            resetImportProcess(e);
          }

          if (validatedKeys.unexpectedKeys.length > 0) {
            showNotification({
              message: `The uploaded CSV contains unexpected column names: ${validatedKeys.unexpectedKeys}`,
              severity: "error",
            });
            resetImportProcess(e);
          }

          // rewrite the plan rows to the format expected by the API
          const formattedPlanInput = csvDataArray.map((plan) => {
            // Split inclusionTitle, inclusionDescription and iconName in case they have multiple values
            plan.inclusionTitles1 = plan.inclusionTitles1.split(";");
            plan.inclusionDescriptions1 = plan.inclusionDescriptions1.split(
              ";"
            );
            plan.inclusionIconNames1 = plan.inclusionIconNames1.split(";");

            if (plan.inclusionTitles1.length > 0) {
              plan.inclusions = [
                {
                  items: [
                    {
                      title: plan.inclusionTitles1[0],
                      iconName: plan.inclusionIconNames1[0] || "",
                      description: plan.inclusionDescriptions1[0],
                    },
                  ],
                  category: plan.inclusionCategory1,
                },
              ];
            }

            // if multiple titles exist, push more items of the same category to inclusions
            if (plan.inclusionTitles1.length > 1) {
              for (let i = 0; i < plan.inclusionTitles1.length; i++) {
                // skip index 0 which has already been created to create the category
                if (i === 0) continue;
                plan.inclusions[0].items.push({
                  title: plan.inclusionTitles1[i],
                  iconName: plan.inclusionIconNames1[i] || "",
                  description: plan.inclusionDescriptions1[i],
                });
              }
            }

            // if there is a second/third category, create a new inclusion item for this category
            if (plan.inclusionCategory2) {
              plan.inclusionTitles2 = plan.inclusionTitles2.split(";");
              plan.inclusionDescriptions2 = plan.inclusionDescriptions2.split(
                ";"
              );
              plan.inclusionIconNames2 = plan.inclusionIconNames2.split(";");

              if (plan.inclusionTitles2.length > 0) {
                plan.inclusions.push({
                  items: [
                    {
                      title: plan.inclusionTitles2[0],
                      iconName: plan.inclusionIconNames2[0] || "",
                      description: plan.inclusionDescriptions2[0],
                    },
                  ],
                  category: plan.inclusionCategory2,
                });
              }

              // if multiple titles exist, push more items of the same category to inclusions
              if (plan.inclusionTitles2.length > 1) {
                for (let i = 0; i < plan.inclusionTitles2.length; i++) {
                  // skip index 0 which has already been created to create the category
                  if (i === 0) continue;
                  plan.inclusions[1].items.push({
                    title: plan.inclusionTitles2[i],
                    iconName: plan.inclusionIconNames2[i] || "",
                    description: plan.inclusionDescriptions2[i],
                  });
                }
              }
            }

            if (plan.inclusionCategory3) {
              plan.inclusionTitles3 = plan.inclusionTitles3.split(";");
              plan.inclusionDescriptions3 = plan.inclusionDescriptions3.split(
                ";"
              );
              plan.inclusionIconNames3 = plan.inclusionIconNames3.split(";");

              if (plan.inclusionTitles3.length > 0) {
                plan.inclusions.push({
                  items: [
                    {
                      title: plan.inclusionTitles3[0],
                      iconName: plan.inclusionIconNames3[0] || "",
                      description: plan.inclusionDescriptions3[0],
                    },
                  ],
                  category: plan.inclusionCategory3,
                });
              }

              // if multiple titles exist, push more items of the same category to inclusions
              if (plan.inclusionTitles3.length > 1) {
                for (let i = 0; i < plan.inclusionTitles3.length; i++) {
                  // skip index 0 which has already been created to create the category
                  if (i === 0) continue;
                  plan.inclusions[1].items.push({
                    title: plan.inclusionTitles3[i],
                    iconName: plan.inclusionIconNames3[i] || "",
                    description: plan.inclusionDescriptions3[i],
                  });
                }
              }
            }

            const generatedHighlightsFromInput = generatePlanHighlights(plan);

            if (generatedHighlightsFromInput.length > 0)
              plan.highlights = generatedHighlightsFromInput;

            if (plan.activityName && plan.venueName) {
              plan.activity = generateActivity(plan);
            }

            const generatedPriceTypesFromInput = generateActivityPriceTypes(
              plan
            );
            if (generatedPriceTypesFromInput.length > 0 && plan.activity) {
              plan.activity.priceTypes = generatedPriceTypesFromInput;
            } else {
              showNotification({
                message: `The uploaded CSV contains invalid input for price types. Please confirm the format matches the expected input and make sure you are providing input for an activity for this plan.`,
                severity: "error",
              });
              resetImportProcess(e);
            }

            const generatedSeatOptionsFromInput = generateActivitySeatOptions(
              plan
            );
            if (generatedSeatOptionsFromInput.length > 0 && plan.activity) {
              plan.activity.seatOptions = generatedSeatOptionsFromInput;
            } else {
              showNotification({
                message: `The uploaded CSV contains invalid input for seat option. Please confirm the format matches the expected input and make sure you are providing input for an activity for this plan.`,
                severity: "error",
              });
              resetImportProcess(e);
            }

            plan.timezone = "Asia/Tokyo";
            plan.basePrice = Number(plan.basePrice);

            if (plan.tagIds && plan.tagIds.length > 0) {
              plan.tagIds = plan.tagIds
                .split(";")
                .map((tagId: string) => Number(tagId));
            } else {
              delete plan.tagIds;
            }

            if (isBooleanCompatibleInput) {
              plan.exclusive = Boolean(plan.exclusive) || false;
              plan.stripe = Boolean(plan.stripe) || false;
              plan.amazonPay = Boolean(plan.amazonPay) || false;
              plan.onSitePayment = Boolean(plan.onSitePayment) || false;
            } else {
              showNotification({
                message: `The uploaded CSV contains unexpected column values for 'exclusive', 'stripe', 'amazonPay' or 'onSitePayment'. Please make sure to only set '1' or no value.`,
                severity: "error",
              });
              resetImportProcess(e);
            }

            // delete all keys that were only used for creating the plan object
            delete plan["inclusionCategory1"];
            delete plan["inclusionTitles1"];
            delete plan["inclusionIconNames1"];
            delete plan["inclusionDescriptions1"];
            delete plan["inclusionCategory2"];
            delete plan["inclusionTitles2"];
            delete plan["inclusionIconNames2"];
            delete plan["inclusionDescriptions2"];
            delete plan["inclusionCategory3"];
            delete plan["inclusionTitles3"];
            delete plan["inclusionIconNames3"];
            delete plan["inclusionDescriptions3"];
            delete plan["highlight1"];
            delete plan["highlight2"];
            delete plan["highlight3"];
            delete plan["activityName"];
            delete plan["venueName"];
            delete plan["courseMenu"];
            delete plan["detailsForVenue"];
            delete plan["offsetMinutes"];
            delete plan["offsetHours"];
            delete plan["commissionRate"];
            delete plan["onSiteCommissionRate"];
            delete plan["questionsByVenue"];
            delete plan["priceType1"];
            delete plan["priceType2"];
            delete plan["priceType3"];
            delete plan["seatOption"];

            return plan;
          });

          importPlans({
            variables: { input: { plans: formattedPlanInput } },
          })
            .then((res) => {
              showNotification({
                message: `Plans were imported successfully`,
                severity: "success",
              });
              setArray(res.data.importPlans.plans);
            })
            .catch((err: any) => {
              showNotification({
                message: `Importing plans failed! ${err}`,
                severity: "error",
              });
            })
            .finally(() => {
              resetImportProcess(e);
            });
        }
      };
      fileReader.readAsText(file);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <h1>IMPORT PLANS </h1>
      <div className={styles.boxed}>
        <ul style={{ textAlign: "left" }}>
          <li>
            For importing plans through a CSV file, please make sure the
            following column names are provided and contain a valid value.
          </li>
          <li>
            The content mostly follows the same rules compared to manually
            saving a plan.
          </li>
          <li>
            You cannot use <em>","</em> comma character anywhere.
          </li>
          <li>Do not add new columns.</li>
          <li>
            The order of columns does not matter, but the names are case
            sensitive.
          </li>
          <li>
            <em>
              tagIds, seatOption, priceType1, inclusionTitles1,
              inclusionDescriptions1 and inclusionIconNames1
            </em>{" "}
            (including higher numbers) can contain multiple values. They should
            be separated by a <em>";"</em> character.
          </li>
          <li>
            <em>exclusive, stripe, amazonPay and oSitePayment</em> accept a{" "}
            <em>"1"</em> character for setting the value or no input if it
            should not be set.
          </li>
          <li>
            Columns with optional input:{" "}
            <em>
              basePriceName, locationName, tagIds, questionsByVenue,
              detailsForVenue, offsetMinutes, offsetHours, onSiteCommissionRate,
              inclusionIconNames1, inclusionCategory2, inclusionTitles2,
              inclusionDescriptions2, highlight1, priceType1 (includes higher
              numbers), seatOption and remarks
            </em>
            .
          </li>
          <li>
            When creating priceType and/or seatOption, activityName and
            venueName become mandatory.
          </li>
          <li>
            The expected input for the optional columns highlight1 (and higher
            numbers) is as follows: text of title<em>;</em>text of details
            <br /> If you want to set exclusive, also add <em>";1"</em>
          </li>
          <li>
            The expected input for the optional columns priceType1 (and higher
            numbers) is as follows: text of name<em>;</em>text of unit type (名
            or セット)<em>;</em>number of amount<em>;</em>number of contracted
            amount<em>;</em>number of min attendees<em>;</em>number of max
            attendees (or empty)
          </li>
          <li>
            The expected input for the optional column seatOption is as follows:
            text of title<em>;</em>text of details<em>;</em>number of min
            attendees<em>;</em>number of max attendees
            <em>;</em>price (or empty)
          </li>
        </ul>
      </div>
      <ul className={styles.list}>
        {[...REQUIRED_KEYS, ...OPTIONAL_KEYS]
          .sort((keyX, keyY) => keyX.length - keyY.length)
          .map((key) => (
            <li key={key}>{key}</li>
          ))}
      </ul>
      <form onSubmit={handleOnSubmit}>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        <Button type="submit" className={styles.button} disabled={loading}>
          {loading ? "IMPORTING..." : "IMPORT CSV"}
        </Button>
      </form>

      <br />

      <Table>
        <TableHead>
          <TableRow className={styles.header}>
            {headerKeys.map((key) => (
              <TableCell key={key}>{key.toUpperCase()}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {array.map((item, index) => (
            <TableRow key={index}>
              {Object.values(item).map((val, index) => (
                <TableCell key={index}>{val}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

const IMPORT_PLANS_MUTATION = gql`
  mutation ImportPlansMutation($input: ImportPlansInput!) {
    importPlans(input: $input) {
      plans {
        id
        name
        description
        areaName
      }
    }
  }
`;
