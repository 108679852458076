import { useMemo } from "react";
import { useMutation, gql } from "@apollo/client";
import { Plan } from "../../../../types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNotifications } from "../../../../components/Notification";

interface ShadowBanDialogProps {
  open: boolean;
  plan: Plan;
  refetch: () => void;
  setDialogOpen: (value: boolean) => void;
}

export default function ShadowBanDialog({
  open,
  plan,
  refetch,
  setDialogOpen,
}: ShadowBanDialogProps) {
  const { showNotification } = useNotifications();

  const actionText = useMemo(() => {
    return plan.shadowBanned ? "Remove Shadow Ban" : "Set Shadow Ban";
  }, [plan]);

  const [savePlanShadowBannedFlag] = useMutation<
    { success: boolean },
    { input: { planId: string; shadowBanned: boolean | undefined } }
  >(SAVE_PLAN_SHADOW_BANNED_FLAG);

  const closeDialog = () => setDialogOpen(false);

  const handleSave = () => {
    savePlanShadowBannedFlag({
      variables: {
        input: { planId: plan.id, shadowBanned: !plan.shadowBanned },
      },
    })
      .then(() => {
        showNotification({
          severity: "success",
          message: `${actionText} was success`,
        });
        refetch();
      })
      .catch((error) => {
        showNotification({
          severity: "error",
          message: error.message.substring(0, 1000),
        });
      })
      .finally(() => closeDialog());
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{actionText}</DialogTitle>
      <DialogContent>
        Do you really want to {plan.shadowBanned ? "remove" : "set"} a shadow
        ban?
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          No
        </Button>
        <Button color="primary" onClick={handleSave}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const SAVE_PLAN_SHADOW_BANNED_FLAG = gql`
  mutation SavePlanShadowBannedFlag($input: SavePlanShadowBannedFlagInput!) {
    savePlanShadowBannedFlag(input: $input) {
      success
    }
  }
`;
