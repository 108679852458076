import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";

import Item, { ItemType } from "./Item";
import { ActivityReservationType } from "../../ActionButtons/types";
import TextField from "../../../../../components/TextField";
import { useViewBookingContext } from "../..";
import { getActivityName } from "../../Basic";
import { Activity } from "../../../../../types";
import { useMemo } from "react";

interface ItemsProps {
  items: ItemType[];
  setItems: React.Dispatch<React.SetStateAction<ItemType[]>>;
  readOnly?: boolean;
  reservation: ActivityReservationType;
}

function Items({ items, setItems, readOnly, reservation }: ItemsProps) {
  const { newPlan, booking } = useViewBookingContext();

  const currentSeatQuantity = useMemo<number>(
    () =>
      items
        .map((item) => item.quantity)
        .reduce((sum: number, quantity: number) => sum + quantity, 0),
    [items]
  );

  return (
    <Card style={{ backgroundColor: "white" }}>
      <CardHeader title="Course Info" />
      <Divider />
      <CardContent>
        <TextField
          readOnly
          fullWidth
          label={"Course name"}
          value={
            newPlan?.activityName
              ? newPlan?.activityName
              : booking?.planSnapshot?.activities
              ? getActivityName(
                  reservation?.activityId,
                  booking?.planSnapshot?.activities
                )
              : ""
          }
        />
        <Grid container spacing={1} style={{ marginTop: 10 }}>
          {items.map((item: ItemType, index: number) => (
            <Grid key={index} item xs={12} md={6}>
              <Item
                data={item}
                activity={
                  booking?.planSnapshot?.activities
                    ? getActivity(
                        reservation?.activityId,
                        booking?.planSnapshot?.activities
                      )
                    : null
                }
                setQuantity={setQuantity(setItems, index)}
                readOnly={readOnly}
                currentSeatQuantity={currentSeatQuantity}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export function getActivity(id: string, activities: Activity[] | undefined) {
  if (!activities) return null;
  const activity = activities.find((a: Activity) => a.id === id) ?? null;
  return activity;
}

const setQuantity = (
  setItems: React.Dispatch<React.SetStateAction<ItemType[]>>,
  index: number
) => {
  return (quantity: number) =>
    setItems((items) => {
      const adjustedItems = [...items];
      adjustedItems[index] = {
        ...adjustedItems[index],
        quantity: Math.max(quantity, 0),
      };
      return adjustedItems;
    });
};

export default Items;
