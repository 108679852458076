import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

import TextField from "../../../../components/TextField";
import { useEffect, useState, useContext } from "react";
import { Booking, ProductOrder, ProductOrderStatus } from "../../../../types";
import Button from "../../../../components/Button2";
import { useNotifications } from "../../../../components/Notification";
import {
  CloudinaryImage,
  CloudinaryContext,
} from "../../../../components/Cloudinary";
import { GET_BOOKING_IDS_WITH_BUYABLE_ITEMS } from "../../../../components/Menu";
import Checkbox from "../../../../components/Checkbox";

export default function Products(props: { bookingId: string }) {
  const apolloClient = useApolloClient();
  const { showNotification } = useNotifications();
  const [saveProductOrders] = useMutation(SAVE_PRODUCT_ORDERS_MUTATION);
  const { loading, error, data } = useQuery<
    GetBookedProductsResponse,
    GetBookedProductsInput
  >(GET_BOOKED_PRODUCTS, {
    variables: { bookingId: props.bookingId },
  });

  const [productOrders, setProductOrders] = useState<ProductOrder[]>([]);
  const { cloudName } = useContext(CloudinaryContext);

  useEffect(() => {
    if (data && data.productOrders) {
      setProductOrders(data.productOrders.records);
    }
  }, [data]);

  if (loading) return <>Loading...</>;
  if (error) return <>Error! {error.message}</>;

  const onDownload = (imageUrl: string) => {
    const imageSrc = imageUrl
      ?.replace(
        "https://storage.googleapis.com/",
        `https://res.cloudinary.com/${cloudName}/image/upload/fl_attachment:message_card_image/`
      )
      .replace("staging-public-omedetaro", "omedetaro-staging")
      .replace("production-public-omedetaro", "omedetaro");

    const link = document.createElement("a");
    link.href = `${imageSrc}`;
    link.click();
    link.parentNode?.removeChild(link);
  };

  function setProductOrderField<T>(
    fieldName: keyof ProductOrder,
    index: number,
    value: T
  ) {
    setProductOrders((pos) => {
      const changeable = [...pos];
      const toBeChanged = pos[index];
      let newValue: any = {
        ...toBeChanged,
        [fieldName]: value,
      };

      changeable[index] = newValue;
      return changeable;
    });
  }

  function handleSaveProductOrders() {
    const giftmallOrderIdAndTrackingNumber = checkGiftmallOrderIdBeforeTrackingNumber(
      productOrders
    );
    if (!giftmallOrderIdAndTrackingNumber) {
      showNotification({
        message: `You cannot set tracking number without also setting the giftmall order id`,
        severity: "error",
      });
      return;
    }
    saveProductOrders({
      variables: {
        input: {
          productOrders: productOrders.map((po) => {
            // we don't save any data from frames, so they don't need to be used for the input
            const { frames, desiredDeliveryDatetime, ...validPoInput } = po;
            return validPoInput;
          }),
        },
      },
      refetchQueries: ["BookedProductsQuery"],
    })
      .then(() => {
        showNotification({
          message: `Product orders were saved`,
          severity: "success",
        });
        apolloClient.refetchQueries({
          include: [GET_BOOKING_IDS_WITH_BUYABLE_ITEMS],
        });
      })
      .catch((err: any) => {
        showNotification({
          message: `Saving product orders failed! ${err}`,
          severity: "error",
        });
      });
  }

  return (
    <>
      <Grid item xs={12}>
        <Card
          style={{
            padding: "0.5rem",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={handleSaveProductOrders}>Save Product Orders</Button>
        </Card>
      </Grid>
      {productOrders &&
        productOrders.length > 0 &&
        productOrders.map((po, idx) => (
          <>
            <Card key={po.id + "_header"} style={{marginBottom: "1rem"}}>
              <CardHeader
                title={`Product Order ${idx + 1}`}
                key={po.id + "_product_order_card_header" + idx}
              />
              <Divider/>
              <Grid container key={po.id + 1} direction="row">
                <Grid container item xs={12} key={po.id + 2}>
                  <CardContent>
                    <TextField
                      label="Shop URL"
                      size="small"
                      value={
                        po.product?.template?.giftmallUrl ??
                        po.product?.template_temp?.giftmallUrl
                      }
                      readOnly
                      onClick={(_e: any) => {
                        if (
                          !po.product?.template?.giftmallUrl &&
                          !po.product?.template_temp?.giftmallUrl
                        )
                          return;
                        window
                          .open(
                            po.product?.template?.giftmallUrl ??
                            po.product?.template_temp?.giftmallUrl,
                            "_blank",
                            "noopener"
                          )
                          ?.focus();
                      }}
                    />
                    <TextField
                      readOnly={true}
                      label={"Template name"}
                      value={
                        po.product?.template?.overrideDisplayName ??
                        po.product?.template?.displayName ??
                        po.product?.template_temp?.overrideDisplayName ??
                        po.product?.template_temp?.displayName
                      }
                    />
                    <TextField
                      readOnly={true}
                      label={"Product order ID"}
                      value={po.id}
                      key={po.id + "_product_order_id"}
                    />
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems="center"
                      spacing={2}
                      key={po.id + 2}
                    >
                      <Grid item xs={10} key={po.id + 3}>
                        <TextField
                          readOnly={true}
                          label={"Product ID"}
                          value={po.productId}
                          key={po.productId + "_product_id"}
                        />
                      </Grid>
                      <Grid item key={po.id + 4}>
                        <Button
                          style={{marginTop: "0.5rem"}}
                          variant="contained"
                          component={Link}
                          to={`/items/edit/${po.product.templateId}`}
                          target="_blank"
                          rel="noopener"
                          key={po.id + "_edit_button"}
                        >
                          <VisibilityIcon key={po.id + "_edit_button_icon"}/>
                        </Button>
                      </Grid>
                    </Grid>
                    <TextField
                      readOnly={true}
                      label={"Product name"}
                      value={po.product.overrideName ?? po.product.name}
                    />
                    <TextField
                      readOnly={true}
                      label={"Product quantity"}
                      value={po.quantity}
                      key={po.productId + "_quantity"}
                    />
                    <TextField
                      readOnly={true}
                      startAdornment={"¥"}
                      label={
                        po.price
                          ? "Product order price"
                          : "Product selling price"
                      }
                      value={po.price ?? po.product.sellingPrice}
                      key={po.productId + "_price"}
                    />
                    <TextField
                      readOnly={true}
                      key={po.productId + "_status"}
                      required={true}
                      style={{marginTop: "10px"}}
                      label="Order status"
                      value={po.status || ProductOrderStatus.CREATED.toString()}
                    />
                    <TextField
                      label={"Tracking number"}
                      value={po.trackingNumber || ""}
                      helperText={
                        po.booking.status === "CONFIRMED" &&
                        po.status.toString() === "CONFIRMED" &&
                        !po.trackingNumber
                          ? "Please set the tracking number"
                          : ""
                      }
                      onChange={(e: any) => {
                        setProductOrderField(
                          "trackingNumber",
                          idx,
                          e.target.value
                        );
                      }}
                      key={po.productId + "_tracking_number"}
                    />
                    <TextField
                      label={"Giftmall order ID"}
                      value={po.giftmallOrderId || ""}
                      helperText={
                        po.booking.status === "CONFIRMED" &&
                        po.status.toString() === "CREATED" &&
                        !po.giftmallOrderId
                          ? "Please set the giftmall order id"
                          : ""
                      }
                      onChange={(e: any) => {
                        setProductOrderField(
                          "giftmallOrderId",
                          idx,
                          e.target.value
                        );
                      }}
                      key={po.productId + "_giftmall_order_id"}
                    />
                    <TextField
                      readOnly={true}
                      key={po.productId + "_desired_delivery"}
                      style={{ marginTop: "10px" }}
                      label="Desired Delivery Date Time"
                      value={po.desiredDeliveryDatetime}
                      />
                    <br/>
                    <Checkbox
                      style={{marginTop: "13px"}}
                      label="Checking"
                      checked={po.isChecking ?? false}
                      onChange={(checked) => {
                        setProductOrderField(
                          "isChecking",
                          idx,
                          checked ?? false
                        );
                      }}
                    />
                  </CardContent>
                </Grid>
              </Grid>
              {po.frames
                .slice()
                .sort((a, b) => a.frame.index - b.frame.index)
                .map((frame, fidx) => {
                  return (
                    <>
                      <Grid item xs={12} key={po.productId + frame.id}>
                        <CardHeader
                          title={`Product Order ${fidx + 1} Frames`}
                          key={po.id + "_product_order_frame"}
                        />
                        <CardContent>
                          <TextField
                            readOnly={true}
                            label={"Product order frame ID"}
                            value={frame.id || ""}
                            key={
                              po.productId +
                              "_" +
                              frame.id +
                              "_" +
                              "_product_order_frame_id"
                            }
                          />
                          <TextField
                            readOnly={true}
                            label={"Frame ID"}
                            value={frame.frameId || ""}
                            key={
                              po.productId +
                              "_" +
                              frame.id +
                              "_" +
                              frame.frameId +
                              "_frame_id"
                            }
                          />
                          <TextField
                            readOnly={true}
                            label={"Frame Name"}
                            value={
                              frame.frame.overrideName ||
                              frame.frame.name ||
                              `${fidx + 1}個目のフレーム`
                            }
                            key={
                              po.productId +
                              "_" +
                              frame.id +
                              "_" +
                              frame.frame.name +
                              "_frame_name"
                            }
                          />
                          {!!frame.fontId ? (
                            <Grid
                              container
                              item
                              xs={12}
                              alignItems="center"
                              key={po.productId + frame.id + 1}
                            >
                              <Grid
                                item
                                xs={10}
                                key={po.productId + frame.id + 2}
                              >
                                <TextField
                                  readOnly={true}
                                  label={"Font ID"}
                                  value={frame.fontId || ""}
                                  key={po.productId + frame.id + "_font_id"}
                                />
                              </Grid>
                              <Grid item key={po.productId + frame.id + 3}>
                                <Button
                                  style={{
                                    marginTop: "0.5rem",
                                    marginLeft: "1rem",
                                  }}
                                  variant="contained"
                                  component={Link}
                                  to={`/items/fonts/${frame.fontId}`}
                                  target="_blank"
                                  rel="noopener"
                                  key={
                                    po.productId +
                                    frame.id +
                                    "_edit_font_button"
                                  }
                                >
                                  <VisibilityIcon
                                    key={
                                      po.productId +
                                      frame.id +
                                      "_edit_font_button_icon"
                                    }
                                  />
                                </Button>
                              </Grid>
                              <TextField
                                multiline
                                readOnly={true}
                                label={"Value"}
                                value={frame.value || ""}
                                key={
                                  po.productId +
                                  frame.id +
                                  "_product_order_frame_value"
                                }
                              />
                              {frame.font && !frame.frame?.skippable && (
                                <>
                                  <TextField
                                    multiline
                                    readOnly={true}
                                    label={"FontName"}
                                    value={
                                      frame.font?.overrideDisplayName ||
                                      frame.font?.displayName ||
                                      ""
                                    }
                                  />
                                  {!!frame.font &&
                                    frame.font.imageUrl.length > 0 && (
                                      <img
                                        src={frame.font.imageUrl}
                                        style={{
                                          backgroundColor: "lightgray",
                                          height: "200px",
                                          marginTop: "12px",
                                        }}
                                        alt="preview of font"
                                      />
                                    )}
                                </>
                              )}
                            </Grid>
                          ) : frame.stampId ? (
                            <Grid
                              container
                              item
                              xs={12}
                              alignItems="center"
                              key={po.productId + frame.id + 4}
                            >
                              <Grid
                                item
                                xs={10}
                                key={po.productId + frame.id + 5}
                              >
                                <TextField
                                  readOnly={true}
                                  label={"Stamp ID"}
                                  value={frame.stampId || ""}
                                  key={
                                    po.productId +
                                    frame.id +
                                    "_product_order_stamp_id"
                                  }
                                />
                              </Grid>
                              <Grid item key={po.productId + frame.id + 6}>
                                <Button
                                  disabled={frame.stampId === null}
                                  style={{
                                    marginTop: "0.5rem",
                                    marginLeft: "1rem",
                                  }}
                                  variant="contained"
                                  component={Link}
                                  to={`/items/stamps/${frame.stampId}`}
                                  target="_blank"
                                  rel="noopener"
                                  key={
                                    po.productId +
                                    frame.id +
                                    "_edit_stamp_button"
                                  }
                                >
                                  <VisibilityIcon
                                    key={
                                      po.productId +
                                      frame.id +
                                      "_edit_stamp_button_icon"
                                    }
                                  />
                                </Button>
                              </Grid>
                              <TextField
                                multiline
                                readOnly={true}
                                label={"StampName"}
                                value={
                                  frame.stamp?.overrideDisplayName ||
                                  frame.stamp?.displayName ||
                                  "不要"
                                }
                              />
                              {!!frame.stamp && frame.stamp.svgUrl.length > 0 && (
                                <img
                                  src={frame.stamp.svgUrl}
                                  style={{
                                    backgroundColor: "lightgray",
                                    height: "200px",
                                    marginTop: "12px",
                                  }}
                                  alt="preview of stamp"
                                />
                              )}
                            </Grid>
                          ) : frame.imageUrl ? (
                            <>
                              <TextField
                                multiline
                                readOnly={true}
                                label={"Image URL"}
                                value={frame.imageUrl || ""}
                                key={
                                  po.productId +
                                  frame.id +
                                  "_product_order_frame_image_url"
                                }
                              />
                              <div
                                style={{
                                  marginTop: "1rem",
                                  marginLeft: "20%",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Button
                                  onClick={() => onDownload(frame.imageUrl!)}
                                  variant="contained"
                                  color="primary"
                                >
                                  Download Image
                                </Button>
                                <CloudinaryImage
                                  style={{
                                    marginLeft: "1rem",
                                    border: "1px solid black",
                                  }}
                                  src={frame.imageUrl}
                                  transformations={["w_400"]}
                                  alt={"preview of message card user upload"}
                                />
                              </div>
                            </>
                          ) : null}
                        </CardContent>
                      </Grid>
                    </>
                  );
                })}
            </Card>
          </>
        ))}
    </>
  );
}

type GetBookedProductsInput = { bookingId: Booking["id"] };
type GetBookedProductsResponse = {
  productOrders: { records: ProductOrder[] };
};
const GET_BOOKED_PRODUCTS = gql`
  query BookedProductsQuery($bookingId: ID!) {
    productOrders(bookingId: $bookingId) {
      records {
        id
        productId
        bookingId
        quantity
        status
        trackingNumber
        giftmallOrderId
        desiredDeliveryDatetime
        price
        isChecking
        product {
          id
          templateId
          sellingPrice
          name
          overrideName
          template {
            id
            name
            giftmallUrl
            displayName
            overrideDisplayName
            description
          }
          template_temp {
            id
            name
            giftmallUrl
            displayName
            overrideDisplayName
            description
          }
        }
        frames {
          id
          frameId
          fontId
          stampId
          value
          imageUrl
          font {
            id
            name
            imageUrl
            displayName
            overrideDisplayName
          }
          stamp {
            id
            name
            svgUrl
            displayName
            overrideDisplayName
          }
          frame {
            skippable
            index
            name
            overrideName
          }
        }
        booking {
          status
        }
      }
    }
  }
`;

const SAVE_PRODUCT_ORDERS_MUTATION = gql`
  mutation SaveProductOrdersMutation($input: SaveProductOrdersInput!) {
    saveProductOrders(input: $input)
  }
`;

function checkGiftmallOrderIdBeforeTrackingNumber(
  productOrders: ProductOrder[]
) {
  return productOrders.every((po) => {
    if (po.trackingNumber && po.trackingNumber.length > 0) {
      return po.giftmallOrderId ? true : false;
    } else return true;
  });
}
