import { gql, useMutation } from "@apollo/client";
import { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import { MediaRenderer, TextRenderer } from "../components/renderers";
import { CardProps, GetOriginalValueProps } from "../../types";
import s from "../Card.module.css";

type CoursePhoto = {
  id: string;
  src: string;
  isDeleted?: boolean;
  isAdded?: boolean;
  activityId?: string;
  photoIndex: number;
  fieldId?: string;
};

type CourseCardProps = Partial<CardProps> & {
  activityId: string;
  fieldId: string;
  courseMenu: string;
  coursePhotos: CoursePhoto[];
  name: string;
};

const CourseCard = forwardRef((props: CourseCardProps, ref) => {
  const [courseMenu, setCourseMenu] = useState(props.courseMenu);
  const [name, setName] = useState(props.name);
  const [mutation] = useMutation<EditCourseMenuResponse, EditCourseMenuInput>(
    EDIT_COURSE_MENU_MUTATION
  );

  const [editActivity] = useMutation<EditActivityNameResponse, EditActivityNameInput>(
    EDIT_ACTIVITY_NAME_MUTATION
  );

  const [editMutation] = useMutation<SavePhotosResponse, SavePhotosInput>(
    EDIT_PHOTO_MUTATION
  );

  const [deleteMutation] = useMutation<DeletePhotoResponse, DeletePhotoInput>(
    DELETE_PHOTO_MUTATION
  );

  const persistChanges = async () => {
    const input: EditCourseMenuInput["input"] = {
      id: props.fieldId,
      courseMenu,
    };
    await mutation({ variables: { input } });

    const activityInput: EditActivityNameInput["input"] = {
      id: props.fieldId,
      name,
    };

    await editActivity({ variables: { input: activityInput } });

    for (const photo of props.coursePhotos) {
      if (photo.isDeleted) {
        const input: DeletePhotoInput["input"] = {
          id: photo.id,
        };
        await deleteMutation({ variables: { input } });
      }
      if (!photo.isDeleted && photo.fieldId && photo.src && photo.photoIndex) {
        const input: SavePhotosInput["input"] = {
          coursePhoto: {
            id: photo.fieldId!,
            activityId: props.fieldId,
            photoIndex: photo.photoIndex,
            src: photo.src,
          },
        };
        await editMutation({ variables: { input } });
      }
    }
  };

  const getNewValue = () => ({
    courseMenu,
    name,
    coursePhotos: props.coursePhotos,
    id: props.fieldId,
  });

  useImperativeHandle(ref, () => ({
    persistChanges,
    getNewValue,
  }));

  return (
    <div style={{ marginTop: 10 }}>
      <TextRenderer
        label="name"
        text={name}
        setText={setName}
        mutable={!!props.mutable}
      />
      <div style={{
        marginTop: 18,
      }}>
      <TextRenderer
        label="course Menu"
        text={courseMenu}
        setText={setCourseMenu}
        mutable={!!props.mutable}
      />
      </div>
      
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 200px)",
          gap: 10,
          marginTop: 10,
        }}
      >
        {props?.coursePhotos?.map((photo: CoursePhoto) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <MediaRenderer
                mediaType="photo"
                mediaUrl={photo.isDeleted ? "" : photo.src}
              />
              {photo.isAdded && (
                <div className={s.newBox}>
                  <p>NEW</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
});

const getOriginalValue = ({ data, fieldId }: GetOriginalValueProps) => ({
  ...data.activities.find((a: any) => a.id === fieldId),
});

const courseCard = {
  Content: CourseCard,
  getOriginalValue,
};
export default courseCard;

const EDIT_COURSE_MENU_MUTATION = gql`
  mutation EditCourseMenuMutation($input: EditCourseMenuInput!) {
    editCourseMenu(input: $input) {
      courseMenu
    }
  }
`;

const EDIT_PHOTO_MUTATION = gql`
  mutation SaveCoursePhotoMutation($input: SaveCoursePhotoInput!) {
    saveCoursePhoto(input: $input) {
      coursePhoto {
        id
      }
      error
    }
  }
`;

const EDIT_ACTIVITY_NAME_MUTATION = gql`
  mutation EditActivatyNameMutation($input: EditActivityNameInput!) {
    editActivityName(input: $input) {
      name
    }
  }
`;

type SavePhotosResponse = {
  saveCoursePhoto: {
    coursePhoto: CoursePhoto;
    error: string;
  };
};

type SavePhotosInput = {
  input: {
    coursePhoto: CoursePhoto;
  };
};

const DELETE_PHOTO_MUTATION = gql`
  mutation DeleteCoursePhotoMutation($input: DeleteCoursePhotoInput!) {
    deleteCoursePhoto(input: $input) {
      coursePhoto {
        id
      }
      error
    }
  }
`;

type DeletePhotoResponse = {
  deleteCoursePhoto: {
    coursePhoto: CoursePhoto;
    error: string;
  };
};

type DeletePhotoInput = {
  input: {
    id: string;
  };
};

type EditCourseMenuResponse = {
  activity: {
    id: string;
  };
};

type EditCourseMenuInput = {
  input: {
    id: string;
    courseMenu: string;
  };
};

type EditActivityNameResponse = {
  name: string;
};

type EditActivityNameInput = {
  input: {
    id: string;
    name: string;
  };
};
